import { Divider, Tag, Alert } from "antd";
import Zoom from "react-medium-image-zoom";
import Countdown from "./countdown";
import classnames from "classnames";
import * as proxy from "@utils/imgproxy";
import * as R from "ramda";
import ProgressiveImage from "react-progressive-graceful-image";
import "react-medium-image-zoom/dist/styles.css";

export default function Content({ test, testSession, setTestSession }) {
  const { questionIdx } = testSession;
  const question = R.pathOr(null, ["questions", questionIdx], test);

  const getTestContent = () => {
    switch (question.content.type) {
      case "image":
        return (
          <div className="test-content h-full flex flex-col justify-center align-center">
            <Zoom
              overlayBgColorStart="rgba(0, 0, 0, 0)"
              overlayBgColorEnd="rgba(0, 0, 0, 0.7)"
              transitionDuration={200}
              zoomMargin={100}
              wrapStyle={{ outline: "none" }}
            >
              <ProgressiveImage
                src={proxy.getImgUrl(question.content.data)}
                placeholder={proxy.getImgUrl(question.content.data, {
                  width: 50,
                  blur: 1,
                })}
              >
                {(src, loading) => (
                  <img
                    height={300}
                    title={question.problem}
                    alt={question.problem}
                    src={src}
                    className={classnames(
                      "relative transition-all duration-100",
                      {
                        "w-full": loading,
                        "opacity-25": loading,
                      }
                    )}
                    style={{
                      display: "block",
                      width: "100%",
                      height: "auto",
                    }}
                  />
                )}
              </ProgressiveImage>
            </Zoom>
          </div>
        );

      case "text":
        return (
          <div className="test-content">
            <p className="my-5 lg:my-10 text-base lg:text-lg font-medium text-black">
              {question.content.data}
            </p>
          </div>
        );

      default:
        return <div className="test-content">{question.content.data}</div>;
    }
  };

  const getExplanations = () => {
    if (question.explanation && testSession.explanationsOn) {
      return (
        <Alert
          className="w-full mt-5"
          type="warning"
          message={<span className="text-sm font-semibold">Explanation</span>}
          description={
            <p
              className="text-sm mt-5 mb-0 text-black"
              dangerouslySetInnerHTML={{
                __html: question.explanation.replace(
                  /(?:\r\n|\r|\n)/g,
                  "<br />"
                ),
              }}
            />
          }
        />
      );
    }
  };

  return (
    <div className="card">
      <div className="card-content-test h-full">
        <div className="flex justify-between items-center mb-5">
          <span className="font-semibold text-sm m-0">
            Question {questionIdx + 1}
          </span>
          {testSession.timerOn && testSession.page !== "review" && (
            <Countdown
              test={test}
              setTestSession={setTestSession}
              testSession={testSession}
            />
          )}
          {testSession.page === "review" && (
            <Tag className="m-0">Review Mode</Tag>
          )}
        </div>
        {getTestContent()}
        {getExplanations()}
      </div>
    </div>
  );
}
