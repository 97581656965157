import { Button, Badge, Tooltip, Popconfirm } from "antd";
import * as Icons from "@ant-design/icons";
import * as R from "ramda";
import classNames from "classnames";

export default function QuestionMap({
  results,
  test,
  testSession,
  setTestSession,
}) {
  const onReviewStart = (questionIdx = 0) => {
    setTestSession({
      ...testSession,
      page: "review",
      questionIdx,
      timerOn: false,
      explanationsOn: true,
      questionTravelOn: true,
    });
  };

  return (
    <div className="card">
      <div className="card-content">
        <div className="flex justify-between items-center flex-wrap">
          <span className="font-semibold text-lg text-black m-0 p-0">
            Questions
          </span>
          <Tooltip title="Review Mode allows you to go through all questions and see correct answers with explanations.">
            <Button
              onClick={() => onReviewStart(0)}
              type="primary"
              icon={<Icons.IssuesCloseOutlined />}
            >
              Start Review Mode
            </Button>
          </Tooltip>
        </div>
        <div className="grid grid-cols-12 gap-4 w-full mt-10">
          {results.data.records.map((question, i) => (
            <Popconfirm
              title="Navigate to this question?"
              onConfirm={() => onReviewStart(i)}
              cancelText="Cancel"
              okText="Yes"
              overlayClassName="shadown-md"
            >
              <a
                key={i}
                className={classNames(
                  "col-span-6 md:col-span-4 lg:col-span-3 record-card",
                  question.correct
                    ? "bg-slate-50 hover:bg-slate-100"
                    : "bg-red-50 hover:bg-red-100"
                )}
              >
                <span className="text-sm font-semibold text-black m-0 question-chip">
                  Q{i + 1}
                </span>
                <span className="text-xs text-slate-600 ml-0 mt-1 md:mt-0 md:ml-3 question-problem">
                  <Badge color={question.correct ? "green" : "red"} />
                  {R.find(R.propEq("id", question.id), test.questions).problem}
                </span>
              </a>
            </Popconfirm>
          ))}
        </div>
      </div>
    </div>
  );
}
