import React, { createContext, useContext, useEffect, useState } from "react";
import * as API from "@api";
import { auth } from "@utils/firebase";
import {
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  updateProfile,
  getAuth,
} from "firebase/auth";
import * as R from "ramda";
import { message } from "antd";

let allowMarketingEmails = false;

const AuthContext = createContext({
  currentUser: null,
  signInWithGoogle: () => Promise,
  login: () => Promise,
  register: () => Promise,
  logout: () => Promise,
  forgotPassword: () => Promise,
  resetPassword: () => Promise,
  loading: false,
});

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkAuth() {
      setLoading(true);
      const user = await API.Auth.getAuth();

      if (user?.data && !user?.error) {
        setCurrentUser(user.data);
      }

      setLoading(false);

      const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
        if (firebaseUser && !currentUser) {
          const _res = await API.Auth.signinOAuth({
            email: firebaseUser.email,
            token: await firebaseUser.getIdToken(),
            marketing_emails: allowMarketingEmails,
          });

          // Reset marketing emails opt-in back to false
          allowMarketingEmails = false;

          setCurrentUser({
            email: R.pathOr(null, ["data", "user", "email"], _res),
            name: R.pathOr(null, ["data", "user", "display_name"], _res),
            id: R.pathOr(null, ["data", "user", "id"], _res),
            role: R.pathOr(null, ["data", "user", "role"], _res),
            provider: R.pathOr(null, ["data", "user", "signup_method"], _res),
          });
        }
      });

      return () => {
        unsubscribe();
      };
    }

    return checkAuth();
  }, []);

  // useEffect(() => {
  //   console.log("The user is", currentUser);
  // }, [currentUser]);

  async function login({ email, password, token = null }) {
    if (!token) {
      return message.error(
        "Error verifying reCaptcha, please try again or refresh this page",
        2
      );
    }

    setLoading(true);
    const user = await API.Auth.signin({ email, password, token });
    setLoading(false);
    if (user.error || !user) {
      return message.error("Invalid email or password, please try again", 2);
    }

    setCurrentUser({
      email: R.pathOr(null, ["data", "user", "email"], user),
      name: R.pathOr(null, ["data", "user", "display_name"], user),
      id: R.pathOr(null, ["data", "user", "id"], user),
      role: R.pathOr(null, ["data", "user", "role"], user),
      provider: "local",
    });
  }

  async function register(data) {
    setLoading(true);
    const user = await API.Auth.register(data);
    setLoading(false);

    if (user?.error) {
      return message.error(
        user.message || "Server error, please try again later",
        2
      );
    }

    setCurrentUser({
      email: R.pathOr(null, ["data", "user", "email"], user),
      name: R.pathOr(null, ["data", "user", "display_name"], user),
      id: R.pathOr(null, ["data", "user", "id"], user),
      role: R.pathOr(null, ["data", "user", "role"], user),
      provider: "local",
    });
  }

  function forgotPassword(email) {
    // return sendPasswordResetEmail(auth, email, {
    //   url: `http://localhost:3000/login`,
    // });
  }

  // function resetPassword(oobCode, newPassword) {
  // return confirmPasswordReset(auth, oobCode, newPassword);
  // }

  async function resetPassword(data) {
    setLoading(true);
    await API.Auth.resetPassword(data);
    setLoading(false);
  }

  async function logout() {
    setLoading(true);
    await API.Auth.logout();
    signOut(auth);
    setCurrentUser(null);
    setLoading(false);
  }

  function signInWithGoogle(options = {}) {
    allowMarketingEmails = options?.marketing_emails;
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  }

  const value = {
    currentUser,
    signInWithGoogle,
    login,
    register,
    logout,
    forgotPassword,
    resetPassword,
    loading,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
