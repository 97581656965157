import { lazy, useEffect, useState, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import * as API from "@api";
import { message, Divider } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import * as proxy from "@utils/imgproxy";
import { Loading } from "@components";
import Signin from "./signin";
import { useAuth } from "@contexts";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import config from "@config";
import * as R from "ramda";

export default function Order() {
  const { currentUser, loading: authLoading } = useAuth();
  const [params] = useSearchParams();
  const products = (params.get("packages") || "all-in-one-tests").split(",");
  const company = params.get("company");
  const [order, setOrder] = useState({
    products: [],
    company: null,
  });

  useEffect(() => {
    (async function () {
      if (currentUser && !R.isEmpty(order.products)) {
        // setLoading(true);
        const stripe = await loadStripe(config.stripe.pubKey);

        if (!stripe) {
          return message.error("Error creating order", 2);
        }

        const _res = await API.Member.Orders.create({
          product_ids: R.uniq(order.products.map((p) => p.id)),
          company_id: order.company ? order.company.id : null,
        });

        if (_res.error || !_res?.data.payment?.stripe_session_id) {
          return message.error("Error creating order", 2);
        }

        stripe.redirectToCheckout({
          sessionId: _res.data.payment.stripe_session_id,
        });
      }
    })();
  }, [currentUser, order]);

  useEffect(() => {
    (async function () {
      try {
        let productsData = await Promise.all(
          products.map((p) => API.Products.getBySlug({ slug: p }))
        );

        let companyData =
          company &&
          (await API.Companies.getBySlug({
            slug: company,
          }));

        setOrder({
          ...order,
          products: productsData.map((p) => p.data.product),
          company: companyData && companyData.data.company,
        });

        // setLoading(false);
      } catch (error) {
        return message.error("Can't load products, please try again later.", 2);
      }
    })();
  }, []);

  const getOrderSummary = () => {
    if (R.isEmpty(order.products)) {
      return <Loading compact />;
    }

    return (
      <Fragment>
        <h1 className="text-lg mb-5">Order Summary</h1>
        <span className="text-xs text-slate-500 font-semibold uppercase">
          Products
        </span>
        {order?.company && (
          <img
            alt={company.name}
            title={company.name}
            src={proxy.getImgUrl(order.company.logo)}
            className="block w-fit h-8 object-contain object-left my-3"
            style={{ minWidth: "unset", minHeight: "unset" }}
          />
        )}
        <ul className="summary-list">
          {order.products.map((p, i) => (
            <li key={i}>
              <div className="pr-1">
                {order?.company ? (
                  <h6 className="text-base m-0">
                    {order.company.name} {p.name}
                  </h6>
                ) : (
                  <h6 className="text-base m-0">{p.name}</h6>
                )}
                <span className="text-sm my-1 text-slate-400">
                  {p?.meta?.features_short}
                </span>
              </div>
              <h6 className="text-base m-0">£{(p.price / 100).toFixed(2)}</h6>
            </li>
          ))}
        </ul>
        <Divider />
        <div className="flex justify-between items-center my-1">
          <span className="text-xs text-slate-500 font-semibold uppercase">
            Subtotal
          </span>
          <span className="font-semibold text-sm text-slate-500">
            £{(R.sum(order.products.map((p) => p.price)) / 100).toFixed(2)}
          </span>
        </div>
        <div className="flex justify-between items-center my-1">
          <span className="text-lg font-semibold">Total</span>
          <span className="text-lg font-semibold">
            £{(R.sum(order.products.map((p) => p.price)) / 100).toFixed(2)}
          </span>
        </div>
        <Divider />
        <img
          src="/img/payment-logos.png"
          height={25}
          className="block mx-auto"
          alt="Payment Methods"
        />
      </Fragment>
    );
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
      <div className="checkout-layout grid grid-cols-2 gap-0">
        <div className="col-span-2 md:col-span-1 flex flex-col justify-center items-center px-0 md:px-10">
          <div className="card summary-card shadow-xl">
            <div className="card-content">{getOrderSummary()}</div>
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 login-content">
          <a
            className="logo-signin"
            href={config.frontend}
            title="Go to Homepage - Aptitude Tests"
          >
            <img src="/img/logo-dark.svg" alt="Aptitude Tests" />
          </a>
          <Signin />
          <div>
            <p className="my-2 text-sm text-slate-500">
              &copy; 2017–{new Date().getFullYear()} Aptitude Tests |{" "}
              <a href={`${config.frontend}/terms-and-conditions`}>
                Terms &amp; Conditions
              </a>{" "}
              | <a href={`${config.frontend}/privacy-policy`}>Privacy Policy</a>
            </p>
            <small>
              This website uses cookies. If you do not want this website to
              save, access and handle cookies, please leave this page.
            </small>
          </div>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
}
