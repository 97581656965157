import axios from "axios";
import config from "@config";

const API = `${config.api}/products`;

// Get all products
export const getAll = (params) =>
  axios
    .get(`${API}/`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Get product by id
export const getOne = ({ id }) =>
  axios
    .get(`${API}/id/${id}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Get product by slug
export const getBySlug = ({ slug, ...params }) =>
  axios
    .get(`${API}/slug/${slug}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
