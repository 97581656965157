import moment from "moment";

export default function TimeMetrics({ results }) {
  return (
    <div className="card h-full">
      <div className="card-content h-full flex flex-col justify-between">
        <span className="text-3xl lg:text-5xl letter font-bold">
          {moment.utc(results.data.time * 1000).format("mm:ss")}
        </span>
        <div className="mt-5 lg:mt-16">
          <span className="text-lg font-semibold">Time spent</span>
          <span className="block text-sm lg:text-base text-slate-500">
            On this test.
          </span>
        </div>
      </div>
    </div>
  );
}
