import moment from "moment";
import { Area } from "@ant-design/plots";
import { sort } from "ramda";

export default function PersonalChart({ scores, passScore }) {
  if (scores.length === 0) {
    return (
      <div className="w-full my-10">
        <span className="block text-base text-center text-slate-500 w-full px-5">
          You don't have any previous attempts for this test yet.
        </span>
      </div>
    );
  }

  scores = sort(
    (a, b) => moment(a.created_at).utc().diff(moment(b.created_at).utc()),
    scores
  );

  scores = scores.map((score) => ({
    ...score,
    category: "Score",
  }));

  const config = {
    data: [
      ...scores,
      ...scores.map((score) => ({
        ...score,
        score: passScore,
        category: "Pass",
      })),
    ],
    xField: "created_at",
    yField: "score",
    smooth: true,
    xAxis: {
      label: null,
    },
    yAxis: {
      max: 100,
    },
    legend: {
      position: "top",
    },
    seriesField: "category",
    isStack: false,
    colorField: "category",
    tooltip: {
      title: (data) => moment(data).format("HH:mm • DD MMM YYYY"),
    },
    color: ({ category }) => (category === "Pass" ? "#17D89E" : "#4706D1"),
    areaStyle: (data) =>
      data.category === "Pass"
        ? {
            fill: "l(270) 0:#ffffff 1:#17D89E",
            stroke: "#17D89E",
          }
        : {
            fill: "l(270) 0:#ffffff 1:#4706D1",
            stroke: "#4706D1",
          },
  };

  return <Area className="my-7" {...config} />;
}
