import { Table, Tag, Button } from "antd";
import { useState } from "react";
import { Results } from "@components";
import * as Icons from "@ant-design/icons";
import moment from "moment";

export default function DataTable(params) {
  const [resultId, setResultId] = useState(null);

  return (
    <div className="card">
      <div className="card-content">
        <Table
          onChange={params.onChange}
          pagination={params.pagination}
          dataSource={params.dataSource}
          loading={params.loading}
          scroll={{ x: "max-content" }}
          columns={[
            {
              title: "Test",
              dataIndex: "test",
              key: "test",
              align: "left",
              width: 230,
              render: (data, all) => (
                <a
                  className="text-black text-sm font-medium"
                  href={`/tests/${all?.product?.slug}/${data.slug}`}
                  target="_blank"
                >
                  {data.name}
                </a>
              ),
            },
            {
              title: "Passed",
              dataIndex: "passed",
              key: "passed",
              align: "center",
              render: (data) =>
                data ? (
                  <Tag className="m-0" color="green">
                    PASS
                  </Tag>
                ) : (
                  <Tag className="m-0" color="red">
                    FAIL
                  </Tag>
                ),
            },
            {
              title: "Score",
              dataIndex: "score",
              key: "score",
              align: "center",
              render: (data) => (
                <span className="m-0 font-medium text-center text-sm">
                  {data}
                </span>
              ),
            },
            {
              title: "Submitted",
              dataIndex: "created_at",
              key: "created_at",
              align: "center",
              render: (data) => moment(data).format("HH:mm • DD MMM YYYY"),
            },
            {
              dataIndex: "actions",
              key: "actions",
              align: "right",
              render: (_, all) => (
                <Button
                  className="mr-3"
                  onClick={() => setResultId(all.id)}
                  icon={<Icons.EyeOutlined />}
                >
                  Quick View
                </Button>
              ),
            },
          ]}
        />
        <Results
          visible={!!resultId}
          resultId={resultId}
          onClose={() => setResultId(null)}
        />
      </div>
    </div>
  );
}
