import { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import * as API from "@api";
import * as Icons from "@ant-design/icons";
import { Modal, Button, Divider } from "antd";
import { Loading } from "@components";
import config from "@config";

export default function OrderSuccess() {
  const params = useParams();
  const [payment, setPayment] = useState(null);

  useEffect(() => {
    (async function () {
      const _res = await API.Member.Payments.complete({
        stripe_session_id: params.stripe_session_id,
      });
      if (_res.error) {
        return window.open("/order/error", "_self");
      }
      setPayment(_res?.data?.payment);
    })();
  }, []);

  const onQuestionClick = (i) => {
    const questions = [
      {
        question: "Why am I seeing this page?",
        answer: (
          <p>
            Your payment has gone through successfully and this page confirms
            that your purchase is valid. There’s no need for further action,
            except you can go to your <a href="/">Dashboard</a> straight away.
          </p>
        ),
      },
      {
        question: "What if I can't sign in or see an error?",
        answer: (
          <p>
            You may have forgotten your password, if you can't sign in to the
            Dashboard. In this case, you can use 'Forgot Password' service on{" "}
            <a href="/signin">Sign In</a> page to reset your password. If you
            still can't use Dashboard due to an error, please let us know:{" "}
            <a target="_blank" href="mailto:support@aptitude-tests.co.uk">
              support@aptitude-tests.co.uk
            </a>
          </p>
        ),
      },
      {
        question: "Other question?",
        answer: (
          <p>
            Please <a href="mailto:support@aptitude-tests.co.uk">contact us</a>{" "}
            for any other question you have. We reply super-fast, but during
            peak days please allow 1 business day for response.
          </p>
        ),
      },
    ];

    return Modal.info({
      maskClosable: true,
      closable: true,
      okText: "Done",
      title: questions[i].question,
      content: questions[i].answer,
      width: 600,
    });
  };

  const getOrderSummary = () => {
    if (!payment) {
      return <Loading compact />;
    }

    return (
      <Fragment>
        <h1 className="text-lg mb-5">Order Summary</h1>
        <span className="text-xs text-slate-500 font-semibold uppercase">
          Products
        </span>
        <ul className="summary-list">
          <li>
            <h6 className="text-base m-0">{payment.description}</h6>
            <h6 className="text-base m-0">
              £{(payment.amount_paid / 100).toFixed(2)}
            </h6>
          </li>
        </ul>
        <Divider />
        <div className="flex justify-between items-center my-1">
          <span className="text-lg font-semibold">Total Paid</span>
          <span className="text-lg font-semibold">
            £{(payment?.amount_paid / 100).toFixed(2)}
          </span>
        </div>
        <Divider />
        <img
          src="/img/payment-logos.png"
          height={25}
          className="block mx-auto"
          alt="Payment Methods"
        />
      </Fragment>
    );
  };

  return (
    <div className="checkout-layout grid grid-cols-2">
      <div className="flex col-span-2 md:col-span-1 flex-col justify-center items-center mx-0 md:mx-10">
        <div className="card summary-card shadow-xl">
          <div className="card-content">{getOrderSummary()}</div>
        </div>
      </div>
      <div className="col-span-2 md:col-span-1 signin-layout">
        <a href={config.frontend} title="Go to Homepage - Aptitude Tests">
          <img
            className="hidden md:block"
            width={140}
            height={40}
            src="/img/logo-dark.svg"
            alt="Aptitude Tests"
          />
        </a>

        {payment ? (
          <div className="order-status-info my-10 md:my-20">
            <h1 className="text-emerald-500 text-3xl">You're all set!</h1>
            <p className="my-10 text-slate-500">
              Thank you for your payment of £
              {(payment?.amount_paid / 100).toFixed(2)}. You will shortly
              receive two emails for order confirmation and payment receipt. You
              can proceed to the <a href="/">Dashboard</a> straight away.
            </p>
            <div className="faq-btn-row">
              <Button
                className="!m-3 !ml-0 !mt-0"
                href="/"
                type="primary"
                shape="round"
              >
                Start Now
                <Icons.RightOutlined />
              </Button>
              <Button
                className="!m-3 !ml-0 !mt-0"
                href={payment?.stripe_payment_receipt}
                target="_blank"
                hidden={!payment?.stripe_payment_receipt}
                shape="round"
              >
                View receipt
              </Button>
              <Button
                className="!m-3 !ml-0 !mt-0"
                onClick={() => onQuestionClick(0)}
                shape="round"
              >
                Why am I seeing this page?
              </Button>
              <Button
                className="!m-3 !ml-0 !mt-0"
                onClick={() => onQuestionClick(1)}
                shape="round"
              >
                What if I can't sign in or see an error?
              </Button>
              <Button
                className="!m-3 !ml-0 !mt-0"
                onClick={() => onQuestionClick(2)}
                shape="round"
              >
                Other question?
              </Button>
            </div>
          </div>
        ) : (
          <Loading compact />
        )}

        <div>
          <p className="my-2 text-sm text-slate-500">
            &copy; 2017–{new Date().getFullYear()} Aptitude Tests |{" "}
            <a href={`${config.frontend}/terms-and-conditions`}>
              Terms &amp; Conditions
            </a>{" "}
            | <a href={`${config.frontend}/privacy-policy`}>Privacy Policy</a>
          </p>
          <small>
            This website uses cookies. If you do not want this website to save,
            access and handle cookies, please leave this page.
          </small>
        </div>
      </div>
    </div>
  );
}
