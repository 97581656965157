import { Divider, Button } from "antd";
import { useState, useEffect } from "react";
import * as Icons from "@ant-design/icons";
import Fade from "react-reveal/Fade";
import Zoom from "react-medium-image-zoom";
import * as R from "ramda";
import Countdown from "react-countdown";
import classNames from "classnames";
import { RingProgress } from "@ant-design/plots";
import * as proxy from "@utils/imgproxy";

export default function Quiz({ quiz, setQuiz, onFinish, onCancel }) {
  const [answer, setAnswer] = useState(null);
  const [correct, setCorrect] = useState(null);
  const question = quiz?.questions[quiz?.currentIdx];

  useEffect(() => {
    if (question) {
      setAnswer(randomAnswer);
    }
  }, [quiz.currentIdx]);

  const randomAnswer = question?.answers.sort(() => Math.random() - 0.5)[0];

  const onNextQuestion = ({ response = null }) => {
    const isCorrect =
      (answer.correct === 1 && response === "yes") ||
      (answer.correct === 0 && response === "no");

    if (response !== null) {
      setCorrect(
        isCorrect,
        setTimeout(() => setCorrect(null), 1000)
      );
    }

    if (quiz.currentIdx === quiz.questions.length - 1) {
      return setQuiz(
        {
          ...quiz,
          responses: [...quiz.responses, isCorrect],
        },
        onFinish()
      );
    }

    setQuiz({
      ...quiz,
      currentIdx: quiz.currentIdx + 1,
      responses: [...quiz.responses, isCorrect],
    });
  };

  const getQuestionContent = () => {
    if (question.content.type === "image") {
      return (
        <Zoom
          overlayBgColorStart="rgba(0, 0, 0, 0)"
          overlayBgColorEnd="rgba(0, 0, 0, 0.7)"
          transitionDuration={200}
          zoomMargin={100}
          wrapStyle={{ outline: "none" }}
        >
          <img
            className="block w-full rounded-lg my-2"
            alt="Question Content"
            src={proxy.getImgUrl(question.content.data)}
          />
        </Zoom>
      );
    }

    return question.content.data;
  };

  const getAnswerOption = () => {
    if (!answer) {
      return;
    }

    if (answer.content.type === "image") {
      return (
        <Zoom
          overlayBgColorStart="rgba(0, 0, 0, 0)"
          overlayBgColorEnd="rgba(0, 0, 0, 0.7)"
          transitionDuration={200}
          zoomMargin={100}
          wrapStyle={{ outline: "none" }}
        >
          <img
            className="block w-full rounded-lg my-2"
            alt="Answer Content"
            src={proxy.getImgUrl(answer.content.data)}
          />
        </Zoom>
      );
    }

    return answer.content.data;
  };

  return (
    <div key={question?.id} className="p-7 quiz-content-overlay">
      {!R.isNil(correct) && !correct && (
        <span className="inform-chip incorrect animate-[ping_1s_ease-in-out]">
          Incorrect
        </span>
      )}
      {!R.isNil(correct) && correct && (
        <span className="inform-chip animate-[ping_1s_ease-in-out]">
          Correct
        </span>
      )}
      <div className="flex justify-between items-center">
        <span className="text-sm m-0 font-medium text-slate-600">
          Question {quiz.currentIdx + 1} / {quiz.questions.length}
        </span>
        <div className="flex items-center">
          <div className="w-8 h-8">
            <Countdown
              key={question?.id}
              onComplete={onNextQuestion}
              date={Date.now() + 90 * 1000}
              renderer={({ total }) => {
                const config = {
                  animation: false,
                  autoFit: true,
                  percent: ((total / 1000) * 100) / 90 / 100,
                  statistic: {
                    content: {
                      style: {
                        fontSize: 12,
                        fontWeight: 500,
                        color: "#000000",
                      },
                      formatter: () => total / 1000,
                    },
                  },
                };
                return (
                  <RingProgress renderer="svg" key={question?.id} {...config} />
                );
              }}
            />
          </div>
          <Button
            onClick={onCancel}
            className="ml-2"
            shape="circle"
            icon={<Icons.CloseOutlined />}
          />
        </div>
      </div>
      <Fade opposite cascade delay={200} duration={600} distance="5px" bottom>
        <div className="quiz-content">
          <div className="chat">
            <div className="yours messages">
              <div
                className={classNames(
                  "message",
                  question.content.type === "image" && "w-full"
                )}
              >
                {getQuestionContent()}
              </div>
              <div className="message last">{question.problem}</div>
            </div>
            <div className="mine messages">
              <div className="message last">{getAnswerOption()}</div>
            </div>
          </div>
        </div>
      </Fade>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <Divider>Is this response correct?</Divider>
        </div>
        <div className="col-span-1 flex justify-center items-center">
          <Button
            size="large"
            block
            type="primary"
            onClick={() => onNextQuestion({ response: "no" })}
            shape="round"
            danger
          >
            No
          </Button>
        </div>
        <div className="col-span-1 flex justify-center items-center">
          <Button
            size="large"
            block
            type="primary"
            onClick={() => onNextQuestion({ response: "yes" })}
            shape="round"
          >
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
}
