import { useState } from "react";
import * as Icons from "@ant-design/icons";
import { Button, Divider, Modal } from "antd";
import Answers from "./answers";
import Reporter from "../reporter";
import * as R from "ramda";

export default function Navigator({
  test,
  testSession,
  setTest,
  setTestSession,
}) {
  const { questionIdx, page } = testSession;
  const [reporterVisible, setReporterVisible] = useState(false);
  const question = R.pathOr(null, ["questions", questionIdx], test);

  const onPrevClick = (e) => {
    e.preventDefault();

    if (questionIdx === 0) {
      return;
    }

    setTestSession({
      ...testSession,
      questionIdx: questionIdx - 1,
    });
  };

  const onNextClick = (e) => {
    e.preventDefault();

    if (questionIdx === test.questions.length - 1) {
      if (page === "review") {
        return setTestSession({
          ...testSession,
          page: "results",
        });
      }

      return Modal.confirm({
        title: "Submit test?",
        content: (
          <p>
            The test will end and results will be calculated. You will be able
            to see correct answers and question explanations.
          </p>
        ),
        onOk: () => {
          setTestSession({
            ...testSession,
            page: "results",
            questionIdx: 0,
          });
        },
        okText: (
          <>
            Submit <Icons.ArrowRightOutlined />
          </>
        ),
        cancelText: "Cancel",
      });
    }

    setTestSession({
      ...testSession,
      questionIdx: questionIdx + 1,
    });
  };

  return (
    <div className="card sticky top-4">
      <div className="card-content-test">
        <div className="flex justify-between items-center mb-2">
          <span className="font-semibold text-sm m-0">Choose answer</span>
          <Button
            onClick={() => setReporterVisible(true)}
            size="small"
            className="m-0 p-0"
            danger
            type="link"
          >
            Report Error
          </Button>
        </div>
        <Divider className="my-3" />
        <p className="text-base font-semibold text-black my-5">
          {question.problem}
        </p>
        <Answers test={test} testSession={testSession} setTest={setTest} />
        <Divider className="mt-3 mb-5" />
        <div className="flex justify-between items-center">
          <Button
            onClick={onPrevClick}
            hidden={!testSession.questionTravelOn}
            disabled={testSession.questionIdx === 0}
          >
            Back
          </Button>
          <Button onClick={onNextClick} type="primary">
            {questionIdx === test.questions.length - 1
              ? page === "review"
                ? "Go to Results"
                : "Submit Test"
              : "Next"}{" "}
            <Icons.ArrowRightOutlined />
          </Button>
        </div>
      </div>
      <Reporter
        test={test}
        testSession={testSession}
        visible={reporterVisible}
        onClose={() => setReporterVisible(false)}
      />
    </div>
  );
}
