import { useState, useEffect } from "react";
import { Loading } from "@components";
import { Link } from "react-router-dom";
import { message, Modal, Button } from "antd";
import moment from "moment";
import Chart from "./chart";
import * as API from "@api";
import * as Icons from "@ant-design/icons";
import classnames from "classnames";

export default function ResultsExtended({
  visible = false,
  resultId = null,
  onClose,
}) {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);

  useEffect(() => {
    if (!resultId) {
      return;
    }

    (async () => {
      setLoading(true);
      const _res = await API.Member.Scores.getOne({ id: resultId });

      if (_res.error) {
        return message.error("Can't find result, please try again later", 2);
      }

      setResults(_res.data.score);
      setLoading(false);
    })();
  }, [resultId]);

  const getResultsData = () => {
    if (!results) {
      if (loading) {
        return <Loading compact />;
      }

      <p className="flex items-center justify-center h-60">
        No results found.
      </p>;
    }

    return (
      <div className="my-10 grid grid-cols-12 gap-8">
        <div className="col-span-6 md:col-span-4">
          <h6 className="text-xs uppercase text-slate-500 font-semibold">
            Test
          </h6>
          <a
            target="_blank"
            href={`/tests/${results?.product?.slug}/${results?.test?.slug}`}
          >
            {results.test.name}
          </a>
        </div>
        <div className="col-span-6 md:col-span-4">
          <h6 className="text-xs uppercase text-slate-500 font-semibold">
            Date Taken
          </h6>
          <span>
            {moment(results.created_at).format("DD MMM YYYY — HH:mm")}
          </span>
        </div>
        <div className="col-span-6 md:col-span-4">
          <h6 className="text-xs uppercase text-slate-500 font-semibold">
            Result
          </h6>
          {results.passed ? (
            <span className="text-sm font-semibold text-green-500">PASSED</span>
          ) : (
            <span className="text-sm font-semibold text-red-500">FAILED</span>
          )}
        </div>
        <div className="col-span-6 md:col-span-4">
          <h6 className="text-xs uppercase text-slate-500 font-semibold">
            Total
          </h6>

          <span
            className={classnames("font-semibold text-sm", {
              "text-green-500": results.passed,
              "text-red-500": !results.passed,
            })}
          >
            {results.score}{" "}
            <i className="not-italic font-normal text-slate-400">/ 100</i>
          </span>
        </div>
        <div className="col-span-6 md:col-span-4">
          <h6 className="text-xs uppercase text-slate-500 font-semibold">
            Percentile
          </h6>
          <span
            className={classnames("font-semibold text-sm text-orange-400", {
              "text-green-500": results.percentile > 70,
            })}
          >
            {results.percentile}{" "}
            <i className="not-italic font-normal text-slate-400">/ 100</i>
          </span>
        </div>
        <div className="col-span-6 md:col-span-4">
          <h6 className="text-xs uppercase text-slate-500 font-semibold">
            Accuracy
          </h6>
          <span
            className={classnames("font-semibold text-sm text-orange-400", {
              "text-green-500": results.accuracy > 70,
            })}
          >
            {results.accuracy}{" "}
            <i className="not-italic font-normal text-slate-400">/ 100</i>
          </span>
        </div>
        <div className="col-span-6 md:col-span-4">
          <h6 className="text-xs uppercase text-slate-500 font-semibold">
            Time Spent
          </h6>
          <span className="text-sm font-semibold">
            {moment.utc(results.time * 1000).format("HH:mm:ss")}
          </span>
        </div>
        <div className="col-span-6 md:col-span-4">
          <h6 className="text-xs uppercase text-slate-500 font-semibold">
            Correct Answers
          </h6>
          <span className="font-semibold text-sm">
            {results.questions_correct}{" "}
            <i className="not-italic font-normal text-slate-400">
              / {results.questions_total}
            </i>
          </span>
        </div>
        <div className="col-span-6 md:col-span-4">
          <h6 className="text-xs uppercase text-slate-500 font-semibold">
            Score to Pass
          </h6>
          <span className="font-semibold text-sm">
            {results.test.pass_score}{" "}
            <i className="not-italic font-normal text-slate-400">/ 100</i>
          </span>
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="card--modal"
      centered
      width={900}
      visible={visible}
      onCancel={onClose}
      destroyOnClose
      cancelText="Done"
      cancelButtonProps={{ type: "primary" }}
      okButtonProps={{ hidden: true }}
      footer={[
        results?.records && (
          <Link className="mr-6" to={`/results/${results.id}`} target="_blank">
            <Button
              type="link"
              className="p-0 h-fit"
              icon={<Icons.HistoryOutlined />}
            >
              Walkthrough
            </Button>
          </Link>
        ),
        <Button type="primary" onClick={onClose}>
          Done
        </Button>,
      ]}
    >
      <div className="card-content">
        <h3 className="mt-0 mb-5 text-xl">Results overview</h3>
        {getResultsData()}
        {!loading && results && (
          <Chart key={results.id} test_id={results.test.id} />
        )}
      </div>
    </Modal>
  );
}
