import { Layouts, Breadcrumb } from "@components";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Products from "./products";
import Orders from "./orders";
import * as API from "@api";
import * as R from "ramda";

export default function Membership() {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    (async () => {
      const orders = await API.Member.Orders.getAllActive();
      if (orders.data) {
        const userPackages =
          R.uniq(
            R.flatten(orders?.data?.rows.map((order) => order.products))
          ) || [];

        setPackages(userPackages);
        setOrders(orders.data.rows);
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Layouts.MemberLayout>
      <div className="container max-content details-page">
        <Breadcrumb
          data={[
            {
              title: "Membership",
              href: `/membership`,
            },
          ]}
        />
        <h1 className="mb-10 text-3xl">Your Membership</h1>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 md:col-span-3">
            <ul className="package-list">
              <li>
                <Link to="/profile">Details</Link>
              </li>
              <li>
                <Link className="active" to="/membership">
                  Membership
                </Link>
              </li>
              <li>
                <Link to="/payments">Payments</Link>
              </li>
            </ul>
          </div>
          <div className="col-span-12 md:col-span-9">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 lg:col-span-6">
                <Products loading={loading} packages={packages} />
              </div>
              <div className="col-span-12 lg:col-span-6">
                <Orders loading={loading} orders={orders} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts.MemberLayout>
  );
}
