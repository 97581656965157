import { Offline } from "react-detect-offline";
import { Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function OfflineWidget(params) {
  return (
    <Offline>
      <div className="offline-msgbox">
        <Alert
          className="msg"
          type="error"
          icon={<LoadingOutlined />}
          banner
          message="No Internet Connection"
          description="You are offline, please check your internet connection before submitting test."
        />
      </div>
    </Offline>
  );
}
