import { Button } from "antd";
import { Link } from "react-router-dom";
import * as Icons from "@ant-design/icons";
import PersonalChart from "@components/chart/personal";

export default function Chart({ results, test }) {
  return (
    <div className="card">
      <div className="card-content">
        <div className="flex justify-between items-center flex-wrap">
          <span className="font-semibold text-lg text-black m-0 p-0">
            Your previous scores
          </span>
          <Link to="/results" target="_blank">
            <Button
              type="link"
              className="p-0 m-0"
              icon={<Icons.LineChartOutlined />}
            >
              View All
            </Button>
          </Link>
        </div>
        <PersonalChart
          scores={results.data.history}
          passScore={test.pass_score}
        />
      </div>
    </div>
  );
}
