import { Layouts } from "@components";
import * as Icons from "@ant-design/icons";

export default function NotFound() {
  return (
    <Layouts.MemberLayout>
      <div className="container max-content dashboard-page">
        <div className="w-full text-center my-20 lg:my-40">
          <Icons.DisconnectOutlined className="text-4xl text-slate-400 mb-2" />
          <h4 className="text-2xl text-center w-full">Not found</h4>
          <p>
            The page you're looking is not found. If you think this is a
            mistake, please{" "}
            <a href="support@aptitude-tests.co.uk">contact us</a>.
          </p>
        </div>
      </div>
    </Layouts.MemberLayout>
  );
}
