import { Link, useLocation, useNavigate } from "react-router-dom";
import { Loading } from "@components";
import { Layout, Menu, Avatar, Dropdown } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  ProfileOutlined,
  CreditCardOutlined,
  HomeOutlined,
  AreaChartOutlined,
  BarsOutlined,
} from "@ant-design/icons";

const { Header, Content, Footer } = Layout;

export default function MemberLayout(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const selectedKeys = (alias) => {
    if (location?.pathname === "/") {
      return ["home"];
    }

    const loc = location.pathname.substr(1).split("/");
    return [loc[0]];
  };

  return (
    <Layout className="site-layout bgr-grey">
      <Header className="header">
        <div className="container max-content flex flex-row justify-between items-center">
          <div className="nav-container">
            <Link
              to="/"
              className="logo hidden md:block"
              style={{ width: 123, height: 35 }}
            >
              <img
                src="/img/logo.svg"
                alt="Aptitude Tests - Member's Dashboard"
                width={123}
                height={35}
                style={{ maxWidth: 123, maxHeight: 35, userSelect: "none" }}
              />
            </Link>
            <Link to="/" className="logo block md:hidden">
              <img
                src="/img/logo-short.svg"
                alt="Aptitude Tests - Member's Dashboard"
              />
            </Link>
            <Menu
              className="topnav hidden lg:block"
              theme="dark"
              mode="horizontal"
              selectedKeys={selectedKeys() || ["home"]}
            >
              <Menu.Item key="home">
                <Link to="/">Home</Link>
              </Menu.Item>
              <Menu.Item key="tests">
                <Link to="/tests">Tests</Link>
              </Menu.Item>
              <Menu.Item key="results">
                <Link to="/results">Results</Link>
              </Menu.Item>
            </Menu>
            <Menu
              className="topnav block lg:hidden"
              theme="dark"
              mode="horizontal"
              selectedKeys={selectedKeys() || ["home"]}
            >
              <Menu.Item key="home">
                <Link to="/">
                  <HomeOutlined />
                </Link>
              </Menu.Item>
              <Menu.Item key="tests">
                <Link to="/tests">
                  <BarsOutlined />
                </Link>
              </Menu.Item>
              <Menu.Item key="results">
                <Link to="/results">
                  <AreaChartOutlined />
                </Link>
              </Menu.Item>
            </Menu>
          </div>
          <Dropdown
            overlayStyle={{ width: 200 }}
            overlay={
              <Menu>
                <Menu.Item icon={<SettingOutlined />}>
                  <Link to="/profile">Profile</Link>
                </Menu.Item>
                <Menu.Item icon={<ProfileOutlined />}>
                  <Link to="/membership">Membership</Link>
                </Menu.Item>
                <Menu.Item icon={<CreditCardOutlined />}>
                  <Link to="/payments">Payments</Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item danger icon={<LogoutOutlined />}>
                  <Link className="!transition-none" to="/logout">
                    Logout
                  </Link>
                </Menu.Item>
              </Menu>
            }
          >
            <Avatar
              size="large"
              style={{ minWidth: 40 }}
              icon={<UserOutlined style={{ color: "rgba(0,0,0,0.4)" }} />}
            />
          </Dropdown>
        </div>
      </Header>
      <Content>
        <div className="site-layout-content">
          {props?.loading ? <Loading /> : props.children}
        </div>
      </Content>
      <Footer className="mt-20 mb-5 text-center">
        <div className="container max-content">
          <small className="text-slate-500">
            Assessment material presented on this website is intented only for
            practice—do not use for employee selection.
          </small>
          <span className="block mt-2 text-sm">
            Aptitude Tests © 2017 – {new Date().getFullYear()} | Need help?{" "}
            <a href="mailto:support@aptitude-tests.co.uk">Contact Us</a>
          </span>
        </div>
      </Footer>
    </Layout>
  );
}
