import * as Pages from "@pages";

export default function MemberRoutes() {
  return [
    {
      path: "/",
      element: <Pages.Dashboard />,
    },
    {
      path: "/results/:resultId",
      element: <Pages.ViewResults />,
    },
    {
      path: "/results",
      element: <Pages.Results />,
    },
    {
      path: "/tests",
      element: <Pages.Tests />,
    },
    {
      path: "/tests/:package",
      element: <Pages.Tests />,
    },
    {
      path: "/tests/:package/:slug",
      element: <Pages.Test />,
    },
    {
      path: "/profile",
      element: <Pages.Profile />,
    },
    {
      path: "/membership",
      element: <Pages.Membership />,
    },
    {
      path: "/payments",
      element: <Pages.Payments />,
    },
    {
      path: "*",
      element: <Pages.NotFound />,
    },
  ];
}
