import { message } from "antd";
import { Link } from "react-router-dom";
import { Layouts, Breadcrumb } from "@components";
import { useEffect, useState } from "react";
import Table from "./table";
import Fade from "react-reveal/Fade";
import * as API from "@api";

export default function Payments() {
  const [tableLoading, setTableLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  useEffect(() => {
    getTableData();
  }, []);

  async function getTableData(params = pagination) {
    setTableLoading(true);
    const _res = await API.Member.Payments.getAll({
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setData(_res.data.rows);
    setPagination({ ...params, total: _res.data.count });
    setTableLoading(false);
  }

  return (
    <Layouts.MemberLayout>
      <div className="container max-content details-page">
        <Breadcrumb
          data={[
            {
              title: "Payments",
              href: `/payments`,
            },
          ]}
        />
        <h1 className="mb-10 text-3xl">Your Payments</h1>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 md:col-span-3">
            <ul className="package-list">
              <li>
                <Link to="/profile">Details</Link>
              </li>
              <li>
                <Link to="/membership">Membership</Link>
              </li>
              <li>
                <Link className="active" to="/payments">
                  Payments
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-12 md:col-span-9">
            <Fade duration={600} distance="5px" bottom>
              <Table
                onChange={getTableData}
                pagination={pagination}
                dataSource={data}
                loading={tableLoading}
              />
            </Fade>
          </div>
        </div>
      </div>
    </Layouts.MemberLayout>
  );
}
