import axios from "axios";
import config from "@config";

const API = `${config.api}/member/tests`;

// Get all tests by product id
export const getAllByProductId = ({ id }) =>
  axios
    .get(`${API}/product/${id}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Get test by id
export const getOne = ({ id }) =>
  axios
    .get(`${API}/id/${id}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Get test by slug
export const getOneBySlug = ({ slug }) =>
  axios
    .get(`${API}/slug/${slug}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
