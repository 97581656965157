import { Badge } from "antd";
import GaugeChart from "react-gauge-chart";
import * as R from "ramda";
import ordinal from "ordinal";

export default function AccuracyMetrics({ results }) {
  const accuracy = parseInt(R.pathOr(0, ["data", "scores", "a"], results));
  return (
    <div className="card h-full">
      <div className="card-content h-full flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center flex-wrap">
            <span className="font-semibold text-lg text-black m-0 p-0">
              Accuracy &amp; Precision
            </span>
            {accuracy < 70 && <Badge color="#FA1366" text="POOR" />}
            {accuracy >= 70 && accuracy < 90 && (
              <Badge color="#FFC700" text="AVERAGE" />
            )}
            {accuracy >= 90 && <Badge color="#17D89E" text="GOOD" />}
          </div>

          <GaugeChart
            animDelay={200}
            className="gauge-chart"
            style={{ width: "100%", fontWeight: 500 }}
            colors={["#F30B0B", "#1DE148"]}
            nrOfLevels={20}
            percent={accuracy / 100}
            fontSize="1.6rem"
            textColor="black"
            marginInPercent={0.02}
            needleColor="#e2e8f0"
            needleBaseColor="#e2e8f0"
          />
        </div>

        <p className="text-base text-slate-500 m-0">
          This score shows how accurate you completed this test. Multiple
          factors are taken into account, including time spent, answer
          correctness, and the number of questions answered.
        </p>
      </div>
    </div>
  );
}
