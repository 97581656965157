import { useEffect, useState } from "react";
import { message, Modal } from "antd";
import * as API from "@api";
import Intro from "./intro";
import Quiz from "./quiz";
import Results from "./results";

export default function QuizWidget({ onClose, visible = false }) {
  const [loading, setLoading] = useState(false);
  const [pkg, setPkg] = useState(null);
  const [page, setPage] = useState("intro");
  const [quiz, setQuiz] = useState({
    questions: [],
    currentIdx: 0,
    responses: [],
  });

  useEffect(() => {
    if (page === "quiz") {
      (async function () {
        const _res = await API.Member.Quiz.getQuizBySlug({ slug: pkg });

        if (!_res || _res.error) {
          return message.error(
            "Error loading quiz, try again later or contact support@aptitude-tests.co.uk",
            4
          );
        }

        setQuiz({
          ...quiz,
          questions: _res.data,
          currentIdx: 0,
          responses: [],
        });
        setLoading(false);
      })();
    }
  }, [page]);

  const onStart = (pkg) => {
    setLoading(true);
    setPkg(pkg);
    setPage("quiz");
  };

  const onFinish = () => {
    setPage("results");
  };

  const onCancel = () => {
    onClose(setPage("intro"));
  };

  return (
    <Modal
      maskStyle={{ backgroundColor: "rgba(0,0,0,.9)" }}
      className="quiz-modal"
      closable={false}
      onCancel={onCancel}
      maskClosable={page === "intro"}
      visible={visible}
      footer={null}
      destroyOnClose
      width={550}
    >
      {page === "intro" && <Intro onStart={onStart} loading={loading} />}
      {page === "quiz" && !loading && (
        <Quiz
          onCancel={onCancel}
          quiz={quiz}
          setQuiz={setQuiz}
          loading={loading}
          onFinish={onFinish}
        />
      )}
      {page === "results" && (
        <Results
          onCancel={onCancel}
          pkg={pkg}
          loading={loading}
          quiz={quiz}
          setQuiz={setQuiz}
        />
      )}
    </Modal>
  );
}
