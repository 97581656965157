import Imgproxy from "imgproxy";
import config from "@config";

const imgproxy = new Imgproxy(config.imgproxy);

export function getImgUrl(file, options = {}) {
  const {
    width = null,
    height = null,
    format = "webp",
    gravity = "no",
    enlarge = 1,
    resize = null,
    blur = null,
    quality = 100,
  } = options;

  let img = imgproxy.builder();

  if (width) {
    img.width(width);
  }

  if (height) {
    img.height(width);
  }

  if (resize) {
    img.resizingType(resize);
  }

  if (blur) {
    img.blur(blur);
  }

  return img
    .gravity(gravity)
    .enlarge(enlarge)
    .format(format)
    .quality(quality)
    .generateUrl(`local:///aptitude-tests-co-uk/${file}`);
}
