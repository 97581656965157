import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Loading } from "@components";
import { Link } from "react-router-dom";
import { Divider } from "antd";
import { ShoppingOutlined } from "@ant-design/icons";
import * as API from "@api";
import * as R from "ramda";
import config from "@config";
import * as proxy from "@utils/imgproxy";
import moment from "moment";

export default function Orders({ loading, orders }) {
  if (loading) {
    return <Loading compact />;
  }

  const getOrders = () => {
    if (!orders || orders.length === 0) {
      return (
        <div className="my-20 w-full">
          <p className="text-sm text-slate-600 block w-full text-center">
            No orders found.
          </p>
        </div>
      );
    }

    return orders.map((order, i) => (
      <div key={i} className="w-full my-3">
        <Divider />
        {order?.company && (
          <img
            className="company-logo mb-4"
            src={proxy.getImgUrl(order.company.logo)}
          />
        )}
        <span className="block my-0 text-base text-black font-semibold">
          {order?.description}
        </span>
        <span className="block text-sm mt-1 text-slate-500">
          Order No. AT-20{order.id} • Placed on{" "}
          {moment(order.created_at).format("DD MMM YYYY")}
        </span>

        <div className="grid grid-cols-2 gap-4 mt-5">
          <div className="col-span-1">
            <span className="block text-xs text-slate-600 mb-1">
              Expiration Date
            </span>
            <span className="block text-sm font-medium text-black">
              {moment(order.expires_at).format("DD MMM YYYY")}
            </span>
          </div>
          <div className="col-span-1">
            <span className="block text-xs text-slate-600 mb-1">Status</span>
            <span className="block text-sm font-medium text-green-500">
              Active
            </span>
          </div>
          <div className="col-span-1">
            <span className="block text-xs text-slate-600 mb-1">Payment</span>
            <span className="block text-sm font-medium text-black">
              {order?.payment
                ? `£${(order.payment.amount_paid / 100).toFixed(2)}`
                : "No payment"}
            </span>
          </div>
          <div className="col-span-1">
            <span className="block text-xs text-slate-600 mb-1">Receipt</span>
            <a
              href="/payments"
              className="block text-sm font-medium text-black"
            >
              View Payments
            </a>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <Fade duration={600} distance="5px" bottom>
      <div className="card h-full">
        <div className="card-content h-full">
          <h6 className="m-0 text-lg">Active Orders</h6>
          {getOrders()}
        </div>
      </div>
    </Fade>
  );
}
