import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import * as Pages from "@pages";
import * as API from "@api";
import { Loading } from "@components";
import { useAuth } from "@contexts";

import { RoutesHandler } from "@components";

export default function App(params) {
  const { loading } = useAuth();

  return (
    <Routes>
      <Route path="/free-tests/:id" element={<Pages.Demo byId />} />
      <Route path="/demo/:slug" element={<Pages.Demo />} />
      <Route path="/register" element={<Pages.Signin />} />
      <Route path="/signin" element={<Pages.Signin />} />
      <Route path="/logout" element={<Pages.Logout />} />
      <Route
        path="/order/cancel/:stripe_session_id"
        element={<Pages.OrderCancel />}
      />
      <Route
        path="/order/success/:stripe_session_id"
        element={<Pages.OrderSuccess />}
      />
      <Route path="/order/error" element={<Pages.OrderError />} />
      <Route path="/order" element={<Pages.Order />} />
      <Route path="*" element={loading ? <Loading /> : <RoutesHandler />} />
    </Routes>
  );
}
