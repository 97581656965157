import { useState } from "react";
import SignInForm from "./form/signin";
import RegisterForm from "./form/register";

export default function Signin() {
  const [isRegister, setIsRegister] = useState(true);

  const onSwitch = () => setIsRegister(!isRegister);

  return isRegister ? (
    <RegisterForm onSwitch={onSwitch} />
  ) : (
    <SignInForm onSwitch={onSwitch} />
  );
}
