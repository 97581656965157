import { Tabs } from "antd";

export default function TestTabs({ testSession, setTestSession, test }) {
  const onQuestionJump = (idx) => {
    setTestSession({
      ...testSession,
      questionIdx: parseInt(idx),
    });
  };

  return (
    <Tabs
      className="test-nav-tabs"
      defaultActiveKey={testSession.questionIdx}
      onChange={onQuestionJump}
      activeKey={String(testSession.questionIdx)}
      hidden={!testSession.questionTravelOn && testSession.page !== "review"}
    >
      {test.questions.map((_, i) => (
        <Tabs.TabPane tab={`Q${i + 1}`} key={i} />
      ))}
    </Tabs>
  );
}
