import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from "react-dom";
import { BrowserRouter, useLocation } from "react-router-dom";
import AuthContextProvider from "@contexts";
import App from "./App";
import "antd/dist/antd.less";
import "@styles/index.scss";

window.Buffer = window.Buffer || require("buffer").Buffer;

function ScrollToTop({ history }) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (window) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [pathname]);

  return null;
}

Sentry.init({
  dsn: "https://61536c88fb544f15b4d2289e9bfcaab5@o1258522.ingest.sentry.io/6432715",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.25,
});

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
