import axios from "axios";
import qs from "qs";
import * as Auth from "./auth";
import * as Companies from "./companies";
import * as Products from "./products";
import * as Member from "./member";
import * as Free from "./free";

axios.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };

  config.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };

  return config;
});

export { Auth, Companies, Products, Member, Free };
