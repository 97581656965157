import classNames from "classnames";

export default function QuestionMetrics({ results }) {
  const { questions } = results.data;

  return (
    <div className="card h-full">
      <div className="card-content h-full flex flex-col justify-between">
        <span
          className={classNames(
            "tracking-tighter text-3xl lg:text-5xl letter font-bold",
            results.data.passed ? "text-green-500" : "text-orange-400"
          )}
        >
          {questions.correct}
          <sup className="tracking-tight text-black font-semibold text-sm lg:text-base leading-none align-top top-2 ml-2">
            / {questions.total}
          </sup>
        </span>
        <div className="mt-5 lg:mt-16">
          <span className="text-base lg:text-lg font-semibold">Questions</span>
          <span className="block text-sm lg:text-base text-slate-500">
            Answered correct.
          </span>
        </div>
      </div>
    </div>
  );
}
