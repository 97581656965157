import { Table, Tag } from "antd";
import moment from "moment";

export default function DataTable(params) {
  return (
    <div className="card">
      <div className="card-content">
        <span className="block font-medium text-base mb-2">
          View your payments
        </span>
        <span className="block text-sm text-slate-600 mb-5">
          Your payments are shown here. If you can't find your payment or you
          have a question related to your purchase, please Contact Us.
        </span>
        <Table
          onChange={params.onChange}
          pagination={params.pagination}
          dataSource={params.dataSource}
          loading={params.loading}
          scroll={{ x: "max-content" }}
          columns={[
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
              align: "left",
              width: 230,
              render: (data) => (
                <span className="text-base font-medium text-black">{data}</span>
              ),
            },
            {
              title: "Amount",
              dataIndex: "amount_paid",
              key: "amount_paid",
              align: "center",
              render: (data) => (
                <span className="block text-sm text-black">
                  £{(data / 100).toFixed(2)}
                </span>
              ),
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              align: "center",
              render: (data) => {
                if (data === "cancelled")
                  return (
                    <Tag className="m-0" color="red">
                      Cancelled
                    </Tag>
                  );

                if (data === "awaiting_payment")
                  return (
                    <Tag className="m-0" color="orange">
                      Awaiting Payment
                    </Tag>
                  );

                if (data === "paid")
                  return (
                    <Tag className="m-0" color="green">
                      Paid
                    </Tag>
                  );
              },
            },
            {
              title: "Date",
              dataIndex: "updated_at",
              key: "updated_at",
              align: "center",
              render: (data) => (
                <span className="block m-0 text-center text-sm">
                  {moment(data).format("HH:mm DD MMM YYYY")}
                </span>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}
