import { Layouts, Breadcrumb } from "@components";
import WelcomeCard from "./cards/welcome";
import MetricsCard from "./cards/metrics";
import TestsCard from "./cards/tests";
import HistoryCard from "./cards/history";
import HelpCard from "./cards/help";
import Fade from "react-reveal/Fade";
import { useState } from "react";

export default function Dashboard(params) {
  return (
    <Layouts.MemberLayout>
      <div className="container max-content dashboard-page">
        <Breadcrumb />
        <h1 className="mb-10 text-3xl">Dashboard</h1>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <Fade delay={200} duration={600} distance="5px" bottom>
              <WelcomeCard />
            </Fade>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <Fade delay={300} duration={600} distance="5px" bottom>
              <TestsCard />
            </Fade>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <Fade delay={400} duration={600} distance="5px" bottom>
              <HistoryCard />
            </Fade>
          </div>
          <div className="col-span-12">
            <HelpCard />
          </div>
        </div>
      </div>
    </Layouts.MemberLayout>
  );
}
