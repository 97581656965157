import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as API from "@api";
import * as R from "ramda";
import { Button } from "antd";

export default function TestsCard() {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    (async () => {
      const orders = await API.Member.Orders.getAllActive();
      if (orders.data) {
        const userPackages =
          R.uniq(
            R.flatten(orders?.data?.rows.map((order) => order.products))
          ) || [];

        setPackages(userPackages);
      }
    })();
  }, []);

  return (
    <div className="card h-full">
      <div className="card-content h-full">
        <h3 className="mt-0 mb-5 text-xl">Tests</h3>
        <ul className="test-list mt-5 mb-0">
          <li>
            <div className="flex flex-row items-center">
              <img
                alt="Numerical Tests"
                src="/img/icons/tests/numerical.svg"
                width={28}
                height={28}
                className="block mr-5"
              />
              <div className="flex flex-col">
                <span className="text-base text-black">Numerical Tests</span>
                <i className="not-italic font-normal text-xs text-slate-500">
                  14 Tests &middot;{" "}
                  {R.includes(3, packages)
                    ? "Unlimited Access"
                    : "Not Purchased"}
                </i>
              </div>
            </div>
            {R.includes(3, packages) ? (
              <Link to="/tests/numerical-tests">
                <Button>Open</Button>
              </Link>
            ) : (
              <Link to="/order?packages=numerical-tests">
                <Button>Upgrade</Button>
              </Link>
            )}
          </li>
          <li>
            <div className="flex flex-row items-center">
              <img
                alt="Verbal Tests"
                src="/img/icons/tests/verbal.svg"
                width={28}
                height={28}
                className="block mr-5"
              />
              <div className="flex flex-col">
                <span className="text-base text-black">Verbal Tests</span>
                <i className="not-italic font-normal text-xs text-slate-500">
                  15 Tests &middot;{" "}
                  {R.includes(4, packages)
                    ? "Unlimited Access"
                    : "Not Purchased"}
                </i>
              </div>
            </div>
            {R.includes(4, packages) ? (
              <Link to="/tests/verbal-tests">
                <Button>Open</Button>
              </Link>
            ) : (
              <Link to="/order?packages=verbal-tests">
                <Button>Upgrade</Button>
              </Link>
            )}
          </li>
          <li>
            <div className="flex flex-row items-center">
              <img
                alt="Logical Tests"
                src="/img/icons/tests/logical.svg"
                width={28}
                height={28}
                className="block mr-5"
              />
              <div className="flex flex-col">
                <span className="text-base text-black">Logical Tests</span>
                <i className="not-italic font-normal text-xs text-slate-500">
                  16 Tests &middot;{" "}
                  {R.includes(5, packages)
                    ? "Unlimited Access"
                    : "Not Purchased"}
                </i>
              </div>
            </div>
            {R.includes(5, packages) ? (
              <Link to="/tests/logical-tests">
                <Button>Open</Button>
              </Link>
            ) : (
              <Link to="/order?packages=logical-tests">
                <Button>Upgrade</Button>
              </Link>
            )}
          </li>
          <li>
            <div className="flex flex-row items-center">
              <img
                alt="Situational Judgement Tests"
                src="/img/icons/tests/sjt.svg"
                width={28}
                height={28}
                className="block mr-5"
              />
              <div className="flex flex-col">
                <span className="text-base text-black">
                  Situational Judgement Tests
                </span>
                <i className="not-italic font-normal text-xs text-slate-500">
                  4 Tests &middot;{" "}
                  {R.includes(6, packages)
                    ? "Unlimited Access"
                    : "Not Purchased"}
                </i>
              </div>
            </div>
            {R.includes(6, packages) ? (
              <Link to="/tests/situational-judgement-tests">
                <Button>Open</Button>
              </Link>
            ) : (
              <Link to="/order?packages=situational-judgement-tests">
                <Button>Upgrade</Button>
              </Link>
            )}
          </li>
          <li>
            <div className="flex flex-row items-center">
              <img
                alt="Mechanical Tests"
                src="/img/icons/tests/mechanical.svg"
                width={28}
                height={28}
                className="block mr-5"
              />
              <div className="flex flex-col">
                <span className="text-base text-black">Mechanical Tests</span>
                <i className="not-italic font-normal text-xs text-slate-500">
                  6 Tests &middot;{" "}
                  {R.includes(7, packages)
                    ? "Unlimited Access"
                    : "Not Purchased"}
                </i>
              </div>
            </div>
            {R.includes(7, packages) ? (
              <Link to="/tests/mechanical-tests">
                <Button>Open</Button>
              </Link>
            ) : (
              <Link to="/order?packages=mechanical-tests">
                <Button>Upgrade</Button>
              </Link>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}
