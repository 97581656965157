import axios from "axios";
import config from "@config";

const API = `${config.api}/member/quiz`;

// Get all tests by product id
export const getQuizBySlug = ({ slug }) =>
  axios
    .get(`${API}/get/${slug}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Submit rating
export const rateQuiz = (body) =>
  axios
    .post(`${API}/rate`, body, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Get ratings
export const getRates = () =>
  axios
    .get(`${API}/rate`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
