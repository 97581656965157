import { Badge } from "antd";
import GaugeChart from "react-gauge-chart";
import * as R from "ramda";

export default function OverallMetrics({ results }) {
  const score = R.pathOr(0, ["data", "scores", "s"], results);
  return (
    <div className="card h-full">
      <div className="card-content h-full flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center flex-wrap">
            <span className="font-semibold text-lg text-black m-0 p-0">
              Overall Score
            </span>
            {R.pathOr(false, ["data", "passed"], results) ? (
              <Badge color="#17D89E" text="PASS" />
            ) : (
              <Badge color="#FA1366" text="FAIL" />
            )}
          </div>

          <GaugeChart
            animDelay={200}
            className="gauge-chart"
            style={{ width: "100%", fontWeight: 500 }}
            colors={["#F30B0B", "#1DE148"]}
            nrOfLevels={20}
            percent={score / 100}
            fontSize="1.6rem"
            textColor="black"
            marginInPercent={0.02}
            needleColor="#e2e8f0"
            needleBaseColor="#e2e8f0"
          />
        </div>

        <p className="text-base text-slate-500 m-0">
          This score indicates your overall performance on this test. It is
          calculated based on your correct answers. Please note, that this score
          alone might not be enough to pass the test in real life.
        </p>
      </div>
    </div>
  );
}
