import { useEffect, createRef } from "react";
import { Form, Input, Button, Divider, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Navigate } from "react-router-dom";
import { LoginButton } from "@components";
import { useAuth } from "@contexts";
import config from "@config";

export default function SignInForm({ onSwitch }) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const form = createRef();
  const { loading, currentUser, register, signInWithGoogle } = useAuth();

  async function handleSocialLogin() {
    const agreement = form.current.getFieldValue("agreement");
    const marketing_emails = form.current.getFieldValue("marketing_emails");

    if (!agreement) {
      return message.error("Please agree to the terms and conditions", 2);
    }

    await signInWithGoogle({ marketing_emails });
  }

  async function handleRegister(data) {
    data.token = await executeRecaptcha();
    await register(data);
  }

  return (
    <div className="mt-10 mb-10">
      <h1>Register to continue</h1>

      <div className="login-form">
        <LoginButton.Google
          loading={loading || !!currentUser}
          onClick={handleSocialLogin}
        />
        <Divider style={{ fontSize: 10 }}>OR</Divider>
      </div>

      <Form
        tabIndex={1}
        className="login-form"
        name="signin"
        size="large"
        initialValues={{ remember: true }}
        onFinish={handleRegister}
        ref={form}
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter your name",
            },
          ]}
        >
          <Input
            disabled={loading || !!currentUser}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Name"
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email",
            },
            {
              type: "email",
              validateTrigger: "onSubmit",
              message: "Invalid email format",
            },
          ]}
        >
          <Input
            disabled={loading || !!currentUser}
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            disabled={loading || !!currentUser}
          />
        </Form.Item>
        <Form.Item
          className="login-checkbox"
          style={{ marginTop: 30 }}
          name="agreement"
          valuePropName="checked"
          initialValue={true}
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Please agree to the Terms before proceeding")
                    ),
            },
          ]}
        >
          <Checkbox>
            Agree to the{" "}
            <a target="_blank" href={`${config.frontend}/terms-and-conditions`}>
              Terms &amp; Conditions
            </a>{" "}
            and{" "}
            <a target="_blank" href={`${config.frontend}/privacy-policy`}>
              Privacy Policy
            </a>
          </Checkbox>
        </Form.Item>
        <Form.Item
          className="login-checkbox"
          name="marketing_emails"
          valuePropName="checked"
          initialValue={true}
        >
          <Checkbox>
            Opt-in for emails (best practices, tips and updates)
          </Checkbox>
        </Form.Item>
        <Form.Item className="mt-3">
          <Button
            type="primary"
            htmlType="submit"
            className="admin-login-form-button"
            loading={loading || !!currentUser}
            block
          >
            Register
          </Button>
        </Form.Item>
        <Form.Item style={{ fontSize: 15 }}>
          <div className="flex justify-between items-center">
            <span className="block">Already have an account?</span>
            <a onClick={onSwitch}>Sign In</a>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
