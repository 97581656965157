import axios from "axios";
import config from "@config";

const API = `${config.api}/free`;

// Get test by slug
export const getOneBySlug = ({ slug }) =>
  axios
    .get(`${API}/tests/${slug}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Error",
    }));

// Get test by Id
export const getOne = ({ id }) =>
  axios
    .get(`${API}/tests/id/${id}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Error",
    }));

// Submit test results
export const submit = (data) =>
  axios
    .post(`${API}/scores`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
