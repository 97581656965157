import { Button, Checkbox } from "antd";
import classNames from "classnames";
import { useState, useEffect } from "react";
import config from "@config";
import { isEmpty, includes, without, append } from "ramda";

const tests = [
  {
    id: "numerical-tests",
    name: "Numerical Tests",
  },
  {
    id: "verbal-tests",
    name: "Verbal Tests",
  },
  {
    id: "situational-judgement-tests",
    name: "Situational Tests",
  },
  {
    id: "mechanical-tests",
    name: "Mechanical Tests",
  },
  {
    id: "logical-tests",
    name: "Logical Tests",
    extra: "Includes Logical, Diagrammatic and Inductive",
  },
];

export default function QuotaModal({ company = null }) {
  // const router = useRouter();
  const [selected, setSelected] = useState([]);
  const [href, setHref] = useState(`${config.basepath}/order`);

  useEffect(() => {
    const qs = `?packages=${selected.join(",")}`;
    setHref(`${config.basepath}/order${qs}`);
  }, [selected]);

  function onCheck(id) {
    if (includes(id, selected)) {
      return setSelected(without([id], selected));
    }

    setSelected(append(id, selected));
  }

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-8 p-4 lg:px-8">
        <h3 className="block text-lg font-semibold text-black w-full max-w-xs mt-0">
          Oops! Looks like you've exceeded your daily quota of free tests.
        </h3>
        <span className="block text-base font-normal text-neutral-600 w-full max-w-xs mt-0">
          Purchase one of our plan and get unlimited access to all 60+ full
          tests:
        </span>
        <div className="grid grid-cols-2 gap-3 mt-5">
          <div className="col-span-2 lg:col-span-1 rounded-lg border border-solid border-neutral-200 p-4 w-full h-full flex flex-col justify-between">
            <div>
              <span className="block font-semibold text-black text-base leading-none">
                {company
                  ? `${company.name} All-in-One ${new Date().getFullYear()}`
                  : "All-in-One Bundle"}
              </span>

              <span className="block text-sm w-full text-neutral-500 leading-snug mt-2">
                Practice{" "}
                <i className="not-italic underline text-black">all our 60+</i>{" "}
                tests for 12 months with unlimited platform access.
              </span>

              <ul className="list-none m-0 p-0 my-4">
                {tests.map((test, i) => (
                  <li
                    key={i}
                    className="p-0 m-1 ml-0 flex flex-wrap justify-start items-center text-sm text-black"
                  >
                    <img
                      src="/img/icon-check.svg"
                      className="block mr-1"
                      width={16}
                      height={16}
                      alt="Feature"
                    />{" "}
                    {test.name}
                    {test.extra && (
                      <i className="block not-italic text-xs text-neutral-500 ml-2 lg:ml-5 lg:mt-1">
                        {test.extra}
                      </i>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <Button
              href={
                company
                  ? `${config.basepath}/order?company=${company.slug}`
                  : `${config.basepath}/order`
              }
              block
              type="primary"
            >
              Buy Now £23.99
            </Button>
          </div>
          <div className="col-span-2 lg:col-span-1 rounded-lg border border-solid border-neutral-200 p-4 w-full h-full flex flex-col justify-between">
            <div>
              <span className="block font-semibold text-black text-base leading-none">
                {company
                  ? `${company.name} Individual ${new Date().getFullYear()}`
                  : "Individual Tests"}
              </span>

              <span className="block text-sm w-full text-neutral-500 leading-snug mt-2">
                Practice{" "}
                <i className="not-italic underline text-black">selected</i>{" "}
                tests for 12 months with unlimited platform access:
              </span>

              <ul className="list-none m-0 p-0 my-4">
                {tests.map((test, i) => (
                  <li key={i} className="p-0 m-1 ml-0">
                    <Checkbox
                      checked={includes(test.id, selected)}
                      onChange={() => onCheck(test.id)}
                    >
                      {test.name}
                    </Checkbox>
                    {test.extra && (
                      <i className="block not-italic text-xs text-neutral-500 ml-2 lg:ml-6 lg:mt-1">
                        {test.extra}
                      </i>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <Button
              href={company ? `${href}&company=${company.slug}` : href}
              disabled={isEmpty(selected)}
              block
              type="primary"
            >
              {isEmpty(selected)
                ? "Select Package"
                : `Buy Now £${(selected.length * 7.99).toFixed(2)}`}
            </Button>
          </div>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-4 hidden justify-end items-center lg:flex">
        <img
          src="/img/quota-img.png"
          className="block w-full object-contain object-right"
        />
      </div>
    </div>
  );
}
