import { Layouts, Breadcrumb } from "@components";
import Fade from "react-reveal/Fade";
import { useEffect, useState } from "react";
import { message } from "antd";
import Table from "./table";
import * as API from "@api";

export default function Results() {
  const [tableLoading, setTableLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  const [filter, setFilter] = useState({
    product_id: undefined,
    passed: undefined,
  });

  useEffect(() => {
    getTableData();
  }, [filter]);

  async function getTableData(params = pagination) {
    setTableLoading(true);
    const _res = await API.Member.Scores.getAll({
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setData(_res.data.rows);
    setPagination({ ...params, total: _res.data.count });
    setTableLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  return (
    <Layouts.MemberLayout>
      <div className="container max-content dashboard-page">
        <Breadcrumb
          data={[
            {
              title: "Results",
              href: `/results`,
            },
          ]}
        />
        <h1 className="mb-10 text-3xl">Results</h1>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <Fade delay={200} duration={600} distance="5px" bottom>
              <Table
                onChange={getTableData}
                pagination={pagination}
                dataSource={data}
                loading={tableLoading}
              />
            </Fade>
          </div>
        </div>
      </div>
    </Layouts.MemberLayout>
  );
}
