import { useEffect, useState } from "react";
import { Loading } from "@components";
import { Modal, Button, Popconfirm } from "antd";
import * as API from "@api";
import * as Icons from "@ant-design/icons";
import Fade from "react-reveal/Fade";
import calcResults from "./calc";
import OverallMetrics from "./metrics/overall";
import AccuracyMetrics from "./metrics/accuracy";
import PercentileMetrics from "./metrics/percentile";
import QuestionMetrics from "./metrics/questions";
import QuestionUnansweredMetrics from "./metrics/questions_unanswered";
import TimeMetrics from "./metrics/time";
import AverageTimeMetrics from "./metrics/averagetime";
import QuestionMap from "./questions";
import Chart from "./chart";
import Purchase from "./purchase";

export default function Results({
  test,
  testSession,
  setTestSession,
  requestPurchase = false,
}) {
  const dictionary = {
    pass: ["Pass", "Nice one!", "Good one", "You passed", "Well done", "Great"],
    fail: ["Fail", "You can do better", "Missed it", "Let's try again?"],
  };
  const [loading, setLoading] = useState(true);
  const results = testSession?.results?.data;

  useEffect(() => {
    const onError = () => {
      return Modal.error({
        title: "Error submitting results",
        content:
          "It seems that something went wrong. Please try again or contact us at support@aptitude-tests.co.uk.",
        onOk: submitResults,
        okText: "Try Again",
      });
    };

    async function submitResults() {
      if (!testSession?.results?.submitted) {
        const resultsRaw = calcResults({ test, testSession });

        setLoading(true);

        const _res = testSession.isFree
          ? await API.Free.submit({
              test_id: test.id,
              ...resultsRaw,
            })
          : await API.Member.Scores.submit({
              test_id: test.id,
              ...resultsRaw,
            });

        if (_res.error || !_res || _res instanceof Error) {
          return onError();
        }

        setTestSession({
          ...testSession,
          results: {
            submitted: true,
            data: {
              ..._res.data,
              questions: resultsRaw.questions,
              records: resultsRaw.records,
              time: resultsRaw.time,
            },
          },
        });
      }
      setLoading(false);
    }

    submitResults();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="results-page">
      <div className="status">
        <div className="flex justify-start items-center">
          <img
            className="status-icon"
            src={
              results.passed
                ? "/img/tests/icon-check.svg"
                : "/img/tests/icon-cross.svg"
            }
            alt="Passed"
          />
          <div>
            <h3 className="font-semibold text-black text-lg lg:text-xl m-0">
              {results?.passed
                ? dictionary.pass[
                    Math.floor(Math.random() * dictionary.pass.length)
                  ]
                : dictionary.fail[
                    Math.floor(Math.random() * dictionary.fail.length)
                  ]}
            </h3>
            <span className="text-slate-600 text-sm lg:text-base m-0">
              {results?.passed
                ? "You passed this test. See report below."
                : "You didn't pass this test. See report below."}
            </span>
          </div>
        </div>
        <Popconfirm
          title="Are you sure?"
          cancelText="Cancel"
          okText="Yes"
          onConfirm={() => document.location.reload()}
        >
          <Button
            className="m-0 p-0 ml-3"
            type="link"
            icon={<Icons.UndoOutlined />}
          >
            Re-take Test
          </Button>
        </Popconfirm>
      </div>
      <div className="grid grid-cols-12 gap-4 lg:gap-6">
        <div className="col-span-12 lg:col-span-4">
          <Fade duration={600} distance="5px" bottom>
            <OverallMetrics results={testSession.results} />
          </Fade>
        </div>
        <div className="col-span-12 lg:col-span-4">
          <Fade delay={100} duration={600} distance="5px" bottom>
            <PercentileMetrics results={testSession.results} />
          </Fade>
        </div>
        <div className="col-span-12 lg:col-span-4">
          <Fade delay={200} duration={600} distance="5px" bottom>
            <AccuracyMetrics results={testSession.results} />
          </Fade>
        </div>
        <div className="col-span-6 md:col-span-3">
          <Fade delay={400} duration={600} distance="5px" bottom>
            <QuestionMetrics results={testSession.results} />
          </Fade>
        </div>
        <div className="col-span-6 md:col-span-3">
          <Fade delay={400} duration={600} distance="5px" bottom>
            <QuestionUnansweredMetrics results={testSession.results} />
          </Fade>
        </div>
        <div className="col-span-6 md:col-span-3">
          <Fade delay={400} duration={600} distance="5px" bottom>
            <TimeMetrics results={testSession.results} />
          </Fade>
        </div>
        <div className="col-span-6 md:col-span-3">
          <Fade delay={400} duration={600} distance="5px" bottom>
            <AverageTimeMetrics results={testSession.results} />
          </Fade>
        </div>
        <div className="col-span-12">
          <QuestionMap
            testSession={testSession}
            setTestSession={setTestSession}
            test={test}
            results={testSession.results}
          />
        </div>

        {!testSession.isFree && (
          <div className="col-span-12">
            <Chart test={test} results={testSession.results} />
          </div>
        )}

        {requestPurchase && (
          <div className="col-span-12">
            <Purchase test={test} />
          </div>
        )}
      </div>
    </div>
  );
}
