import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Layouts, Loading, Breadcrumb, TestModules } from "@components";
import { message, Modal } from "antd";
import QuotaModal from "@components/demo/modal";
import classnames from "classnames";
import * as API from "@api";
import * as R from "ramda";
import config from "@config";
import classNames from "classnames";

export default function Test({ byId = false }) {
  const params = useParams("company");
  const [search] = useSearchParams();
  const [test, setTest] = useState(null);
  const [testSession, setTestSession] = useState({
    isFree: true,
    page: "intro",
    questionIdx: 0,
    testStartedAt: null,
    timerOn: true,
    questionTravelOn: true,
    negativeMarkingOn: false,
    explanationsOn: false,
    timeSpent: 0,
    userAnswers: [],
    results: {
      data: null,
      submitted: false,
    },
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const _res = byId
        ? await API.Free.getOne({ id: params.id })
        : await API.Free.getOneBySlug({ slug: params.slug });

      let company = null;

      if (search.get("company")) {
        const _company = await API.Companies.getBySlug({
          slug: search.get("company"),
        });

        company = _company?.data?.company || null;

        if (_res?.data) {
          _res.data.test.name = `${company?.name} ${_res?.data?.test?.name}`;
        }
      }

      if (_res?.error || !_res?.data) {
        return Modal.info({
          centered: true,
          width: 800,
          content: <QuotaModal company={company} />,
          maskClosable: false,
          closable: false,
          bodyStyle: { padding: 0 },
          icon: null,
          keyboard: false,
          okButtonProps: { hidden: true },
        });
      }

      setTest({
        ..._res.data.test,
        company,
      });

      setTestSession({
        ...testSession,
        questionTravelOn: _res.data.test.time !== 0,
        negativeMarkingOn: _res.data.test.negative_marking,
      });

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (testSession.timerOn && test?.time === 0) {
      setTestSession({
        ...testSession,
        questionTravelOn: false,
      });
    }
  }, [testSession.timerOn]);

  if (loading) {
    return (
      <Layouts.FreeLayout>
        <Loading compact />
      </Layouts.FreeLayout>
    );
  }

  return (
    <Layouts.FreeLayout company={test?.company}>
      <div className="container max-content test-page">
        <Breadcrumb
          data={[
            test?.company
              ? {
                  title: `${test.company.name} Free Tests`,
                  href: `${config.frontend}/companies/${test.company.slug}-online-tests`,
                }
              : { title: "Free Tests", href: `${config.frontend}/free-tests` },
            {
              title: R.pathOr("", ["name"], test),
              href: byId
                ? `/free-tests/${R.pathOr("", ["id"], test)}`
                : `/demo/${R.pathOr("", ["slug"], test)}`,
            },
          ]}
        />
        {testSession.page === "intro" && (
          <TestModules.Intro
            test={test}
            testSession={testSession}
            setTestSession={setTestSession}
          />
        )}
        {(testSession.page === "test" || testSession.page === "review") && (
          <TestModules.Tabs
            test={test}
            testSession={testSession}
            setTestSession={setTestSession}
          />
        )}
        {(testSession.page === "test" || testSession.page === "review") && (
          <div className="grid grid-cols-12 gap-4 mt-7">
            <div
              className={classnames("col-span-12", {
                "lg:col-span-8": !/situational/g.test(test.slug),
              })}
            >
              <TestModules.Content
                test={test}
                testSession={testSession}
                setTestSession={setTestSession}
              />
            </div>
            <div
              className={classnames("col-span-12", {
                "lg:col-span-4": !/situational/g.test(test.slug),
              })}
            >
              <TestModules.Navigator
                test={test}
                testSession={testSession}
                setTestSession={setTestSession}
                setTest={setTest}
              />
            </div>
          </div>
        )}
        {testSession.page === "results" && (
          <TestModules.Results
            requestPurchase
            test={test}
            testSession={testSession}
            setTestSession={setTestSession}
          />
        )}
      </div>
      <TestModules.Offline />
    </Layouts.FreeLayout>
  );
}
