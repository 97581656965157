import { useEffect, useState } from "react";
import { Form, Input, Checkbox, message, Button, Modal } from "antd";
import { SaveOutlined, DeleteOutlined, LockOutlined } from "@ant-design/icons";
import { Loading } from "@components";
import Fade from "react-reveal/Fade";
import * as API from "@api";
import { useAuth } from "@contexts";

export default function FormDetails() {
  const { logout, currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const _res = await API.Member.Users.getUser();
      if (_res.error || !_res) {
        return message.error(
          "Can't load details, please try again or contact support.",
          2
        );
      }

      setData(_res.data.user);
      setLoading(false);
    })();
  }, []);

  const onUserDetailsSave = async (data) => {
    const _res = await API.Member.Users.update(data);

    if (!_res || _res.error) {
      return message.error(
        "Can't update details, please try again or contact support.",
        2
      );
    }

    message.success("Your details updated successfully.", 2);
  };

  const onUserPasswordUpdate = async (data) => {
    if (data?.password !== data?.confirm_password) {
      return message.error(
        "New password mismatch. Please re-type your new password.",
        2
      );
    }

    const _res = await API.Member.Users.updatePassword(data);

    if (!_res || _res.error) {
      return message.error(
        "Can't change password, please try again or contact support.",
        2
      );
    }

    message.success("Password changed successfully", 2, () =>
      document.location.reload()
    );
  };

  const onRequestAccountDeletion = async () => {
    return Modal.confirm({
      title: "Are you sure you want to delete your account?",
      okButtonProps: { type: "primary", danger: true, tabIndex: 0 },
      okText: "Delete Permanently",
      centered: true,
      width: 500,
      onOk: async function () {
        const _res = await API.Member.Users.destroy();

        if (!_res || _res.error) {
          return message.error("Something went wrong, please contact us.", 2);
        }

        await logout();

        message.success("Your account has been deleted", 2, () =>
          document.location.reload()
        );
      },
      content: (
        <div>
          <p className="text-sm text-black">
            This action cannot be undone. Your records, membership information,
            and all other data will be permanently deleted. You will not be able
            to recover your account.
          </p>
        </div>
      ),
    });
  };

  if (loading) {
    return <Loading compact />;
  }

  return (
    <Fade duration={600} distance="5px" bottom>
      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-2 lg:col-span-1">
          <div className="card">
            <div className="card-content">
              <h6 className="m-0 text-lg">Update Details</h6>
              <Form
                className="mt-5 mb-0"
                onFinish={onUserDetailsSave}
                name="user"
                layout="vertical"
                initialValues={data}
              >
                <Form.Item
                  name="display_name"
                  className="w-full my-3"
                  label="Name"
                  tooltip="Enter your name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name.",
                    },
                  ]}
                >
                  <Input placeholder="Enter your name" />
                </Form.Item>
                <Form.Item
                  name="email"
                  className="w-full my-3"
                  label="Email"
                  tooltip="Enter your email address"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please enter valid email address.",
                    },
                  ]}
                >
                  <Input type="email" placeholder="Enter your email address" />
                </Form.Item>
                <Form.Item
                  name="marketing_emails"
                  valuePropName="checked"
                  className="w-full mt-5 mb-3"
                >
                  <Checkbox>
                    Receive emails with latest practice tips and new products.
                  </Checkbox>
                </Form.Item>
                <Form.Item className="w-full mt-7 mb-0">
                  <div className="flex flex-wrap justify-between items-center">
                    <Button
                      loading={loading}
                      type="primary"
                      className="m-0"
                      icon={<SaveOutlined />}
                      htmlType="submit"
                    >
                      Save Changes
                    </Button>
                    <Button
                      loading={loading}
                      className="m-0 p-0 h-fit"
                      icon={<DeleteOutlined />}
                      type="link"
                      danger
                      onClick={onRequestAccountDeletion}
                    >
                      Delete Account
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="card">
            <div className="card-content">
              <h6 className="m-0 text-lg">Change Password</h6>
              <Form
                className="mt-5 mb-0"
                onFinish={onUserPasswordUpdate}
                layout="vertical"
              >
                <Form.Item
                  name="password"
                  className="w-full my-3"
                  label="New password"
                  rules={[
                    {
                      min: 6,
                      required: true,
                      message: "Password should be at least 6 characters long.",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Enter new password"
                  />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  className="w-full my-3"
                  label="Confirm new password"
                  rules={[
                    {
                      min: 6,
                      required: true,
                      message: "Password should be at least 6 characters long.",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Confirm your new password"
                  />
                </Form.Item>
                <Form.Item className="w-full mt-7 mb-0">
                  <Button
                    loading={loading}
                    type="primary"
                    className="m-0"
                    icon={<SaveOutlined />}
                    htmlType="submit"
                  >
                    Save Changes
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
