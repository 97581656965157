import { useEffect, useState } from "react";
import { Form, Input, Button, Divider, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Navigate } from "react-router-dom";
import { LoginButton } from "@components";
import { useAuth } from "@contexts";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import config from "@config";

export default function SignInForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [resetting, setResetting] = useState(false);
  const { loading, login, signInWithGoogle, resetPassword, currentUser } =
    useAuth();

  // useEffect(() => {
  //   executeRecaptcha()
  //     .then(setToken)
  //     .catch(() => message("Error verifying captcha"));

  //   return executeRecaptcha;
  // }, [executeRecaptcha]);

  // console.log("token", token);

  async function handleSignin(data) {
    data.token = await executeRecaptcha();

    if (resetting) {
      const _res = await resetPassword(data);

      if (_res?.error) {
        return message.error("Something went wrong. Please try again.", 2);
      }

      return message.success(
        _res?.message || "Password reset email sent, please check your inbox",
        1,
        () => setResetting(false)
      );
    }

    await login(data);
  }

  function switchMode(e) {
    e.preventDefault();
    setResetting(!resetting);
  }

  if (currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <div className="mt-10 mb-10">
      <h1>{resetting ? "Forgot Password" : "Sign In"}</h1>

      <div hidden={resetting} className="login-form">
        <LoginButton.Google
          hidden={resetting}
          loading={loading}
          onClick={signInWithGoogle}
        />
        <Divider style={{ fontSize: 10 }}>OR</Divider>
      </div>

      <Form
        tabIndex={1}
        className="login-form"
        name="signin"
        size="large"
        initialValues={{ remember: true }}
        onFinish={handleSignin}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email",
            },
            {
              type: "email",
              validateTrigger: "onSubmit",
              message: "Invalid email format",
            },
          ]}
        >
          <Input
            disabled={loading}
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        {!resetting && (
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your password" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              disabled={loading}
            />
          </Form.Item>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="admin-login-form-button"
            loading={loading}
            block
          >
            {resetting ? "Reset Password" : "Sign In"}
          </Button>
        </Form.Item>
        <Form.Item style={{ fontSize: 15 }}>
          <a hidden={resetting} href={`${config.basepath}/register`}>
            Register
          </a>
          <Divider
            hidden={resetting}
            style={{ background: "#5a6479", opacity: 0.5 }}
            type="vertical"
          />
          <a className="admin-login-form-forgot" onClick={switchMode}>
            {resetting ? "Back to Sign In" : "Forgot Password"}
          </a>
        </Form.Item>
      </Form>
    </div>
  );
}
