import Rate from "./rate";
import classNames from "classnames";
import { Button, Divider } from "antd";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import * as R from "ramda";

export default function Results({ quiz, onCancel }) {
  const correctAnswers = R.filter((correct) => correct, quiz.responses);
  const passed = correctAnswers.length >= quiz.questions.length - 3;

  return (
    <div className="p-7">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center">
          <img
            className="status-icon w-12 h-12 mr-2"
            src={
              passed ? "/img/tests/icon-check.svg" : "/img/tests/icon-cross.svg"
            }
            alt="Passed"
          />
          <div>
            <h3 className="font-semibold text-black text-base m-0">
              {passed ? "Pass!" : "Fail"}
            </h3>
            <span className="text-slate-600 text-sm m-0">
              {passed
                ? "You passed this quiz. Nice one!"
                : "You didn't pass this quiz."}
            </span>
          </div>
        </div>
        <Button onClick={onCancel} shape="round" type="primary">
          Done
        </Button>
      </div>
      <Divider />
      <Fade opposite cascade delay={200} duration={600} distance="5px" bottom>
        <div className="flex flex-col justify-center items-center py-16">
          <span
            className={classNames(
              "tracking-tighter text-3xl lg:text-5xl letter font-bold",
              passed ? "text-green-500" : "text-orange-400"
            )}
          >
            {correctAnswers.length}
            <sup className="tracking-tight text-black font-semibold text-sm lg:text-base leading-none align-top top-2 ml-2">
              / {quiz.questions.length}
            </sup>
          </span>
          <div className="mt-6">
            <span className="text-base lg:text-lg font-semibold block text-center w-full">
              Questions
            </span>
            <span className="text-sm lg:text-base text-slate-500 block text-center w-full">
              Answered correct.
            </span>
          </div>
        </div>
      </Fade>
      <Divider />
      {passed ? (
        <Rate />
      ) : (
        <div className="w-full text-center">
          <span className="block text-xl font-medium text-black text-center">
            Need practice?
          </span>
          <span className="block max-w-xs mx-auto text-sm text-slate-500 text-center mt-1">
            Explore our online tests and improve your psychometric &amp;
            problem-solving qualities.
          </span>
          <Link to="/tests">
            <Button className="block mx-auto mt-5" shape="round" type="primary">
              See Tests
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}
