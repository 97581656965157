import { Card, Checkbox, Radio, Divider, Select, Tag } from "antd";
import * as proxy from "@utils/imgproxy";
import * as R from "ramda";

const responseMap = {
  2: ["Least effective", "Most effective"],
  3: ["Least effective", "Effective", "Most effective"],
  4: [
    "Least effective",
    "Effective",
    "Second most effective",
    "Most effective",
  ],
  5: [
    "Least effective",
    "Less effective",
    "Effective",
    "Second most effective",
    "Most effective",
  ],
};

export default function Answers({ test, testSession, setTest }) {
  const { questionIdx, page } = testSession;
  const question = R.pathOr(null, ["questions", questionIdx], test);
  const { answers } = question;

  const onChangeAnswerRadio = (e) => {
    const answerId = e.target.value; // Answer Index

    return onTestQuestionUpdate({
      answerId: answerId,
      value: 1,
    });
  };

  const onChangeAnswerCheckbox = (answerIds) => {
    return onTestQuestionUpdate({
      answerId: answerIds,
      value: true,
    });
  };

  const onChangeSimpleSort = ({ id, value }) => {
    return onTestQuestionUpdate({
      answerId: id,
      value,
    });
  };

  const onTestQuestionUpdate = ({ answerId, value = null }) => {
    const userAnswers = answers.map((answer) => {
      switch (question.type) {
        case 1: // Signle-choice (radio) text
        case 3: // Signle-choice (radio) image
          return {
            ...answer,
            selected: answer.id === answerId ? value : null,
          };

        case 2: // Multiple-choice text
          return {
            ...answer,
            selected: R.includes(answer.id, answerId) || null,
          };

        case 4: // Sort
        case 5: // Sort
          return {
            ...answer,
            selected:
              answer.selected === value
                ? null
                : answer.id === answerId
                ? value
                : answer.selected || null,
          };

        default:
          return answer;
      }
    });

    const questions = test.questions.map((q) =>
      q.id === question.id ? { ...q, answers: userAnswers } : q
    );

    setTest({
      ...test,
      questions,
    });
  };

  // Radio text answer
  if (question.type === 1) {
    return (
      <div>
        <span className="block text-sm text-slate-500 mb-2">Choose one:</span>
        <Radio.Group
          disabled={page === "review"}
          value={R.find(R.propEq("selected", 1), answers)?.id || null}
          onChange={onChangeAnswerRadio}
          key={question.id}
          className="test-answers radio-answers"
        >
          {answers.map((answer, i) => (
            <Radio key={answer.id} className="radio-answer" value={answer.id}>
              {answer.content.data}{" "}
              {page === "review" && answer.correct === 1 && (
                <Tag color="green">Correct</Tag>
              )}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    );
  }

  // Checkbox answer
  if (question.type === 2) {
    return (
      <div>
        <span className="block text-sm text-slate-500 mb-2">
          Choose one or more:
        </span>
        <Checkbox.Group
          disabled={page === "review"}
          value={R.without(
            [null],
            question.answers.map((a) => (a.selected ? a.id : null))
          )}
          onChange={onChangeAnswerCheckbox}
          key={question.id}
          className="test-answers checkbox-answers"
        >
          {answers.map((answer) => (
            <Checkbox
              key={answer.id}
              className="checkbox-answer"
              value={answer.id}
            >
              {page === "review" ? (
                answer.correct === 1 ? (
                  <Tag color="green">{answer.content.data}</Tag>
                ) : (
                  answer.content.data
                )
              ) : (
                answer.content.data
              )}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </div>
    );
  }

  // Radio image answer
  if (question.type === 3) {
    return (
      <div>
        <span className="block text-sm text-slate-500 mb-2">Choose one:</span>
        <Radio.Group
          disabled={page === "review"}
          value={R.find(R.propEq("selected", 1), answers)?.id || null}
          onChange={onChangeAnswerRadio}
          key={question.id}
          className="test-answers radio-answers radio-img"
        >
          {question.answers.map((answer, i) => (
            <Radio
              key={answer.id}
              className={`radio-answer ${
                answer.correct === 1 && page === "review" && "radio-img-correct"
              }`}
              value={answer.id}
            >
              <img
                src={proxy.getImgUrl(answer.content.data)}
                alt={`Answer ${i + 1}`}
              />
            </Radio>
          ))}
        </Radio.Group>
      </div>
    );
  }

  // Best and Worst answer
  if (question.type === 4) {
    return (
      <div>
        <span className="block text-sm text-slate-500">Sort responses:</span>

        <table className="w-full border-collapse">
          <thead className="pb-10">
            <tr>
              <th />
              <th className="w-1/4 lg:w-36 pb-3">Least Effective</th>
              <th className="w-1/4 lg:w-36 pb-3">Most Effective</th>
            </tr>
          </thead>
          <tbody className="mt-2">
            {answers.map((answer, i) => (
              <tr key={i}>
                <td className="border border-solid border-slate-200 align-middle p-4">
                  <p className="text-black text-sm m-0">
                    {answer.content.data}{" "}
                    {page === "review" && answer.correct && (
                      <Tag color="green">
                        {responseMap[2][answer.correct - 1]}
                      </Tag>
                    )}
                  </p>
                </td>
                <td className="border border-solid border-slate-200 align-middle w-1/4 lg:w-36 text-center">
                  <Radio
                    disabled={page === "review"}
                    value={1}
                    checked={answer.selected === 1}
                    className="m-0"
                    onChange={(e) =>
                      onChangeSimpleSort({
                        id: answer.id,
                        value: e.target.value,
                      })
                    }
                  />
                </td>
                <td className="border border-solid border-slate-200 align-middle w-1/4 lg:w-36 text-center">
                  <Radio
                    disabled={page === "review"}
                    value={2}
                    checked={answer.selected === 2}
                    className="m-0"
                    onChange={(e) =>
                      onChangeSimpleSort({
                        id: answer.id,
                        value: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  // Sorted answers
  if (question.type === 5) {
    const numOfResponses = Math.max(
      ...answers.map((a) => parseInt(a.correct) || 1)
    );

    return (
      <div className="overflow-x-scroll">
        <span className="block text-sm text-slate-500">Sort responses:</span>

        <table className="w-full border-collapse">
          <thead className="pb-10">
            <tr>
              <th style={{ minWidth: 250 }} />
              {responseMap[numOfResponses].map((res, i) => (
                <th key={i} className="w-1/4 lg:w-32 pb-3">
                  {res}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="mt-2">
            {answers.map((answer, i) => (
              <tr key={i}>
                <td className="border border-solid border-slate-200 align-middle p-4">
                  <p className="text-black text-sm m-0">
                    {answer.content.data}{" "}
                    {page === "review" && answer.correct && (
                      <Tag color="green">
                        {responseMap[numOfResponses][answer.correct - 1]}
                      </Tag>
                    )}
                  </p>
                </td>

                {responseMap[numOfResponses].map((_, x) => (
                  <td
                    key={x + 1}
                    className="border border-solid border-slate-200 align-middle w-32 text-center"
                  >
                    <Radio
                      disabled={page === "review"}
                      value={x + 1}
                      checked={answer.selected === x + 1}
                      className="m-0"
                      onChange={(e) =>
                        onChangeSimpleSort({
                          id: answer.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
