import axios from "axios";
import config from "@config";

const API = `${config.api}/member/news`;

// Get all news
export const getAll = (params) =>
  axios
    .get(`${API}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
