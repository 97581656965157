import { Breadcrumb } from "antd";

export default function Breadcrumbs({ data = [] }) {
  const items = data.map((item, i) => {
    return (
      <Breadcrumb.Item key={i} href={item.href}>
        {item.title}
      </Breadcrumb.Item>
    );
  });

  return (
    <Breadcrumb className="breadcrumbs-global" separator="/">
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      {items}
    </Breadcrumb>
  );
}
