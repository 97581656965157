import { Layouts, Breadcrumb } from "@components";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Form from "./form";
import { message } from "antd";
import * as API from "@api";

export default function Profile() {
  return (
    <Layouts.MemberLayout>
      <div className="container max-content details-page">
        <Breadcrumb
          data={[
            {
              title: "Profile",
              href: `/profile`,
            },
          ]}
        />
        <h1 className="mb-10 text-3xl">Your Details</h1>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 md:col-span-3">
            <ul className="package-list">
              <li>
                <Link className="active" to="/profile">
                  Details
                </Link>
              </li>
              <li>
                <Link to="/membership">Membership</Link>
              </li>
              <li>
                <Link to="/payments">Payments</Link>
              </li>
            </ul>
          </div>
          <div className="col-span-12 md:col-span-9">
            <Form />
          </div>
        </div>
      </div>
    </Layouts.MemberLayout>
  );
}
