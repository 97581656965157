import { Link } from "react-router-dom";
import { Divider, Checkbox, Button } from "antd";
import { Adsense } from "@ctrl/react-adsense";
import * as API from "@api";
import * as Icons from "@ant-design/icons";
import * as R from "ramda";
import * as proxy from "@utils/imgproxy";
import config from "@config";
import moment from "moment";

export default function Intro(params) {
  const { test, testSession, setTestSession } = params;

  const onTestStart = async () => {
    const testStartedAt = new Date();

    if (!testSession.isFree) {
      try {
        await API.Member.Scores.registerStart({
          test_id: test.id,
          started_at: moment(testStartedAt).format(),
        });
      } catch (_) {
        console.log("Test record not submitted");
      }
    }

    setTestSession({
      ...testSession,
      page: "test",
      testStartedAt,
      timeSpent: 0,
      questionIdx: 0,
    });
  };

  return (
    <div className="grid grid-cols-12 gap-6 mt-7">
      <div className="col-span-12">
        <div className="card">
          <div className="card-content">
            {test?.company?.logo && (
              <img
                src={proxy.getImgUrl(test.company.logo)}
                alt="Logo"
                className="company-logo-test mb-5"
              />
            )}
            <h1 className="text-lg">{test.name}</h1>
            <div className="grid grid-cols-2 gap-6">
              <div className="col-span-2 md:col-span-1">
                <p className="text-sm leading-6">
                  You are starting {test.name}. Before taking this test, we
                  adivse that you read our Recommendations below. You can refer
                  to our{" "}
                  <a href={`${config.frontend}/faq`} target="_blank">
                    FAQ Page
                  </a>{" "}
                  if you have questions. A full test report with results and
                  incorrect answers will be shown after you complete the test.
                  <br />
                  <br />
                  Good luck!
                </p>
              </div>
              <div className="col-span-2 md:col-span-1">
                <i className="test-info-item text-slate-600">
                  <Icons.ProfileOutlined className="mr-2" />{" "}
                  <strong>Questions:</strong>&nbsp;{test.questions.length}
                </i>
                <i className="test-info-item text-slate-600">
                  <Icons.ClockCircleOutlined className="mr-2" />{" "}
                  <strong>Time limit:</strong>&nbsp;
                  {test.time !== 0
                    ? test.time
                    : test.question_time * test.questions.length}{" "}
                  min
                </i>
                <i className="test-info-item text-slate-600">
                  <Icons.CheckCircleOutlined className="mr-2" />{" "}
                  <strong>Pass score:</strong>&nbsp;{test.pass_score}% (~
                  {Math.round(
                    (test.questions.length * test.pass_score) / 100
                  )}{" "}
                  questions)
                </i>
                <i className="test-info-item text-slate-600">
                  <Icons.IssuesCloseOutlined className="mr-2" />{" "}
                  <strong>Negative marking:</strong>&nbsp;
                  {test.negative_marking ? "Yes" : "No"}
                </i>
              </div>
              <div className="col-span-2">
                <Divider className="mt-0" />
                <div
                  className="text-sm text-slate-600 leading-6"
                  dangerouslySetInnerHTML={{ __html: test.description }}
                />
                <div className="grid grid-cols-4 gap-6">
                  <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                    <Checkbox
                      onChange={(e) =>
                        setTestSession({
                          ...testSession,
                          timerOn: e.target.checked,
                        })
                      }
                      checked={testSession.timerOn}
                    >
                      Time Limit
                    </Checkbox>
                  </div>
                  <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                    <Checkbox
                      disabled={test.time === 0 && testSession.timerOn}
                      onChange={(e) =>
                        setTestSession({
                          ...testSession,
                          questionTravelOn: e.target.checked,
                        })
                      }
                      checked={testSession.questionTravelOn}
                    >
                      Allow Question Skip
                    </Checkbox>
                  </div>
                  <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                    <Checkbox
                      onChange={(e) =>
                        setTestSession({
                          ...testSession,
                          explanationsOn: e.target.checked,
                        })
                      }
                      checked={testSession.explanationsOn}
                    >
                      Show Explanations
                    </Checkbox>
                  </div>
                  <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                    <Checkbox
                      onChange={(e) =>
                        setTestSession({
                          ...testSession,
                          negativeMarkingOn: e.target.checked,
                        })
                      }
                      checked={testSession.negativeMarkingOn}
                    >
                      Negative Marking
                    </Checkbox>
                  </div>
                </div>
                <Divider className={testSession.isFree && "mb-3"} />
                {testSession.isFree && (
                  <div>
                    <span className="text-xs block text-slate-500 mb-2">
                      This free test is sponsored by:
                    </span>
                    <Adsense
                      client="ca-pub-9272366314869114"
                      slot="5095411752"
                      className="bg-neutral-50"
                    />
                    <Divider className="mt-3" />
                  </div>
                )}
                <div className="flex justify-between items-center">
                  {testSession.isFree ? (
                    <a href={`${config.frontend}/free-tests`}>
                      <Button>Back to Free Tests</Button>
                    </a>
                  ) : (
                    <Link
                      to={`/tests/${R.pathOr("", ["product", "slug"], test)}`}
                    >
                      <Button>Back to All Tests</Button>
                    </Link>
                  )}

                  <Button type="primary" onClick={onTestStart}>
                    Start Now <Icons.ArrowRightOutlined />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
