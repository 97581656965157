import { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import * as API from "@api";
import { Modal, Button, message, Divider } from "antd";
import { Loading } from "@components";
import config from "@config";

export default function OrderCancel() {
  const params = useParams();
  const [payment, setPayment] = useState(null);

  useEffect(() => {
    (async function () {
      const _res = await API.Member.Payments.cancel({
        stripe_session_id: params.stripe_session_id,
      });

      if (_res.error) {
        return message.error(
          "Invalid session, please try again or contact support.",
          0
        );
      }

      setPayment(_res?.data?.payment);
    })();
  }, []);

  const onQuestionClick = (i) => {
    const questions = [
      {
        question: "Why am I seeing this page?",
        answer: (
          <p>
            {" "}
            You cancelled your order during Checkout. Visit{" "}
            <a href={`${config.frontend}/pricing`}>Pricing</a> page to order
            again.
          </p>
        ),
      },
      {
        question: "What if I had issues on Checkout page?",
        answer: (
          <p>
            If you experienced any errors before, during or after Checkout
            process that affected your purchase experience, please{" "}
            <a href="mailto:support@aptitude-tests.co.uk">let us know</a>, so we
            can help you as soon as we can.
          </p>
        ),
      },
      {
        question: "Is it secure?",
        answer: (
          <p>
            Absolutely. We partnered with{" "}
            <a
              href="https://stripe.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              Stripe
            </a>
            —leading payment provider to give you the most secure and smoothest
            Checkout experience.
          </p>
        ),
      },
      {
        question: "Other question?",
        answer: (
          <p>
            Please <a href="mailto:support@aptitude-tests.co.uk">contact us</a>{" "}
            for any other question you have. We reply super-fast, but during
            peak days please allow 1 business day for response.
          </p>
        ),
      },
    ];

    return Modal.info({
      maskClosable: true,
      closable: true,
      okText: "Done",
      title: questions[i].question,
      content: questions[i].answer,
      width: 600,
    });
  };

  const getOrderSummary = () => {
    if (!payment) {
      return <Loading compact />;
    }

    return (
      <Fragment>
        <h1 className="text-lg mb-5">Order Summary</h1>
        <span className="text-xs text-slate-500 font-semibold uppercase">
          Products
        </span>
        <ul className="summary-list">
          <li>
            <h6 className="text-base m-0">{payment.description}</h6>
            <h6 className="text-base m-0">
              £{(payment.amount_paid / 100).toFixed(2)}
            </h6>
          </li>
        </ul>
        <Divider />
        <div className="flex justify-between items-center my-1">
          <span className="text-lg font-semibold">Total</span>
          <span className="text-lg font-semibold">
            £{(payment?.amount_paid / 100).toFixed(2)}
          </span>
        </div>
        <Divider />
        <img
          src="/img/payment-logos.png"
          height={25}
          className="block mx-auto"
          alt="Payment Methods"
        />
      </Fragment>
    );
  };

  return (
    <div className="checkout-layout grid grid-cols-2">
      <div className="flex col-span-2 md:col-span-1 flex-col justify-center items-center mx-0 md:mx-10">
        <div className="card summary-card shadow-xl">
          <div className="card-content">{getOrderSummary()}</div>
        </div>
      </div>
      <div className="col-span-2 md:col-span-1 signin-layout">
        <a href={config.frontend} title="Go to Homepage - Aptitude Tests">
          <img
            className="hidden md:block"
            width={140}
            height={40}
            src="/img/logo-dark.svg"
            alt="Aptitude Tests"
          />
        </a>

        <div className="order-status-info my-10 md:my-20">
          <h1 className="text-error text-3xl">Order cancelled</h1>
          <p className="my-10 text-slate-500">
            It seems that you cancelled your order. If you wish to select other
            packages, please visit{" "}
            <a href={`${config.frontend}/pricing`} title="Visit Pricing">
              Pricing
            </a>{" "}
            page. If there's something we can improve with Customer Checkout
            experience, please{" "}
            <a href="mailto:support@aptitude-tests.co.uk" title="Contact Us">
              let us know
            </a>
            .
          </p>
          <div className="faq-btn-row">
            <Button onClick={() => onQuestionClick(0)} shape="round">
              Why am I seeing this page?
            </Button>
            <Button onClick={() => onQuestionClick(1)} shape="round">
              What if I had issues on Checkout page?
            </Button>
            <Button onClick={() => onQuestionClick(2)} shape="round">
              Is it secure?
            </Button>
            <Button onClick={() => onQuestionClick(3)} shape="round">
              Other question?
            </Button>
          </div>
        </div>

        <div>
          <p className="my-2 text-sm text-slate-500">
            &copy; 2017–{new Date().getFullYear()} Aptitude Tests |{" "}
            <a href={`${config.frontend}/terms-and-conditions`}>
              Terms &amp; Conditions
            </a>{" "}
            | <a href={`${config.frontend}/privacy-policy`}>Privacy Policy</a>
          </p>
          <small>
            This website uses cookies. If you do not want this website to save,
            access and handle cookies, please leave this page.
          </small>
        </div>
      </div>
    </div>
  );
}
