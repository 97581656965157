import { useState, useEffect, Fragment } from "react";
import { Layouts, Breadcrumb, Loading } from "@components";
import { Button, message, Tooltip, Badge, Alert, Radio } from "antd";
import {
  ClockCircleOutlined,
  TrophyOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import classnames from "classnames";
import productlist from "./products";
import Fade from "react-reveal/Fade";
import * as API from "@api";
import * as R from "ramda";

export default function Tests(props) {
  const { package: pkg = "numerical-tests" } = useParams();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [accessible, setAccessible] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async function () {
      let [product, orders] = await Promise.all([
        API.Products.getBySlug({ slug: pkg }),
        API.Member.Orders.getAllActive(),
      ]);

      product = R.pathOr(null, ["data", "product"], product);
      orders = R.pathOr([], ["data", "rows"], orders);
      const tests = R.pathOr(
        [],
        ["data", "tests"],
        await API.Member.Tests.getAllByProductId({
          id: product?.id,
        })
      );

      if (!product) {
        return message.error("Can't load products, please try again later.", 2);
      }

      const userActiveProducts = R.uniq(
        R.flatten(orders.map((o) => o?.products || []))
      );

      setProduct({
        ...product,
        tests: R.sort(
          (a, b) => a.name.localeCompare(b.name, "en", { numeric: true }),
          tests
        ),
      });
      setAccessible(R.includes(product.id, userActiveProducts));
      setLoading(false);
    })();
  }, [pkg]);

  const getProductList = () => {
    return productlist.map((p, i) => (
      <li key={`product-${i}`}>
        <Link
          key={i}
          className={classnames({ active: pkg === p.slug })}
          to={p.url}
        >
          {p.name}
        </Link>
      </li>
    ));
  };

  const getProductContent = () => {
    if (loading) {
      return <Loading compact />;
    }

    return (
      <Fragment>
        <div className="flex items-center mb-5">
          <img
            className="mr-4"
            width={20}
            height={20}
            alt={product.name}
            src={R.find(R.propEq("slug", pkg), productlist)?.icon}
          />
          <h3 className="m-0 text-xl">{product.name}</h3>
        </div>
        {!accessible && (
          <Alert
            className="my-5"
            message={
              <>
                No active membership found for this package.{" "}
                <a href={`/order?packages=${pkg}`}>Upgrade</a> to unlock all
                tests.
              </>
            }
            type="warning"
            showIcon
          />
        )}
        <div dangerouslySetInnerHTML={{ __html: product?.description }}></div>
        <ul className="test-list my-10">
          {product.tests.map((test, key) => (
            <li key={key}>
              <div>
                <div className="flex items-center">
                  <span className="font-semibold text-base my-0 mr-2">
                    {test.name}
                  </span>
                  <Tooltip
                    placement="bottom"
                    title={
                      test.scores.length === 0
                        ? "Not started"
                        : test.scores[0].passed
                        ? "Passed"
                        : "Failed"
                    }
                  >
                    <Badge
                      status={
                        test.scores.length === 0
                          ? "default"
                          : test.scores[0].passed
                          ? "success"
                          : "error"
                      }
                    />
                  </Tooltip>
                </div>
                <div className="flex items-center mt-2">
                  <Tooltip placement="bottom" title="Test length">
                    <i className="text-xs text-slate-500 not-italic mr-3 select-none">
                      <ClockCircleOutlined className="mr-1" />
                      {test.time !== 0
                        ? `${test.time} min`
                        : `${test.question_time} min/question`}
                    </i>
                  </Tooltip>
                  <Tooltip placement="bottom" title="Minimum score to pass">
                    <i className="text-xs text-slate-500 not-italic mr-3 select-none">
                      <TrophyOutlined className="mr-1" />
                      {test.pass_score}%
                    </i>
                  </Tooltip>
                  <Tooltip placement="bottom" title="Your recent score">
                    <i className="text-xs text-slate-500 not-italic mr-3 select-none">
                      <RiseOutlined className="mr-1" />
                      {test.scores.length === 0
                        ? "Not started"
                        : `${test.scores[0].score}%`}
                    </i>
                  </Tooltip>
                </div>
              </div>
              {accessible ? (
                <Link to={`/tests/${pkg}/${test.slug}`}>
                  <Button type="primary">Take Test</Button>
                </Link>
              ) : (
                <Link to={`/order?packages=${pkg}`}>
                  <Button>Upgrade</Button>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </Fragment>
    );
  };

  return (
    <Layouts.MemberLayout>
      <div className="container max-content tests-page">
        <Breadcrumb
          data={[
            { title: "Tests", href: "/tests" },
            {
              title: R.find(R.propEq("slug", pkg), productlist).name,
              href: `/tests/${pkg}`,
            },
          ]}
        />
        <h1 className="mb-10 text-3xl">Tests</h1>

        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 md:col-span-3">
            <ul className="package-list">{getProductList()}</ul>
          </div>
          <div className="col-span-12 md:col-span-9">
            <Fade key={pkg} duration={600} distance="5px" bottom>
              <div className="card">
                <div className="card-content">{getProductContent()}</div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Layouts.MemberLayout>
  );
}
