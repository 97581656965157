import { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import * as API from "@api";
import { Modal, Button, message, Divider } from "antd";
import { Loading } from "@components";
import config from "@config";

export default function OrderCancel() {
  const onQuestionClick = (i) => {
    const questions = [
      {
        question: "Why am I seeing this page?",
        answer: (
          <p>
            Server returned an error during Checkout process. There is a number
            of reasons for this, but we highly recommend contacting us on{" "}
            <a target="_blank" href="mailto:support@aptitude-tests.co.uk">
              support@aptitude-tests.co.uk
            </a>
            , so we can fix this.
          </p>
        ),
      },
      {
        question:
          "I can see a successful transaction with my bank. What should I do?",
        answer: (
          <p>
            <a target="_blank" href="mailto:support@aptitude-tests.co.uk">
              Contact us
            </a>{" "}
            immediately, so we can process this issue. Please{" "}
            <strong>don't</strong> tell us your sensitive bank details (e.g.
            card number or cvc) when contacting us.
          </p>
        ),
      },
    ];

    return Modal.info({
      maskClosable: true,
      closable: true,
      okText: "Done",
      title: questions[i].question,
      content: questions[i].answer,
      width: 600,
    });
  };

  return (
    <div className="checkout-layout grid grid-cols-2">
      <div className="flex col-span-2 md:col-span-1 flex-col justify-center items-center mx-0 md:mx-10">
        <img
          className="block w-28 h-28 my-10"
          width={112}
          height={112}
          src="/img/thunderstorm.svg"
          title="Error"
          alt="Error"
        />
      </div>
      <div className="col-span-2 md:col-span-1 signin-layout">
        <a href={config.frontend} title="Go to Homepage - Aptitude Tests">
          <img
            className="hidden md:block"
            width={140}
            height={40}
            src="/img/logo-dark.svg"
            alt="Aptitude Tests"
          />
        </a>

        <div className="order-status-info my-10 md:my-20">
          <h1 className="text-error text-3xl">Payment Error</h1>
          <p className="my-10 text-slate-500">
            You don't usually see this page, but it seems that something broke.
            Please{" "}
            <a target="_blank" href="mailto:support@aptitude-tests.co.uk">
              let us know
            </a>{" "}
            as soon as you can, so we can help you restore your purchase
            experience.
          </p>
          <div className="faq-btn-row">
            <Button onClick={() => onQuestionClick(0)} shape="round">
              Why am I seeing this page?
            </Button>
            <Button onClick={() => onQuestionClick(1)} shape="round">
              I can see a successful transaction with my bank. What should I do?
            </Button>
          </div>
        </div>

        <div>
          <p className="my-2 text-sm text-slate-500">
            &copy; 2017–{new Date().getFullYear()} Aptitude Tests |{" "}
            <a href={`${config.frontend}/terms-and-conditions`}>
              Terms &amp; Conditions
            </a>{" "}
            | <a href={`${config.frontend}/privacy-policy`}>Privacy Policy</a>
          </p>
          <small>
            This website uses cookies. If you do not want this website to save,
            access and handle cookies, please leave this page.
          </small>
        </div>
      </div>
    </div>
  );
}
