// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    name: "Numerical Tests",
    slug: "numerical-tests",
    url: "/tests/numerical-tests",
    icon: "/img/icons/tests/two-tone/numerical.svg",
  },
  {
    name: "Verbal Tests",
    slug: "verbal-tests",
    url: "/tests/verbal-tests",
    icon: "/img/icons/tests/two-tone/verbal.svg",
  },
  {
    name: "Situational Judgement Tests",
    slug: "situational-judgement-tests",
    url: "/tests/situational-judgement-tests",
    icon: "/img/icons/tests/two-tone/sjt.svg",
  },
  {
    name: "Logical Tests",
    slug: "logical-tests",
    url: "/tests/logical-tests",
    icon: "/img/icons/tests/two-tone/logical.svg",
  },
  {
    name: "Mechanical Tests",
    slug: "mechanical-tests",
    url: "/tests/mechanical-tests",
    icon: "/img/icons/tests/two-tone/mechanical.svg",
  },
];
