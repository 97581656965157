import { useEffect, useState } from "react";
import * as API from "@api";

export default function Rates() {
  const [userRate, setUserRate] = useState(null);
  const [userRateHistory, setUserRateHistory] = useState(null);
  const [ratings, setRatings] = useState({
    negative: 0,
    positive: 0,
  });

  useEffect(() => {
    (async () => {
      if (userRate) {
        if (userRate === "positive") {
          if (userRateHistory === "negative") {
            await API.Member.Quiz.rateQuiz({ scope: "negative", rating: -1 });
          }
          await API.Member.Quiz.rateQuiz({ scope: "positive", rating: 1 });
        }

        if (userRate === "negative") {
          if (userRateHistory === "positive") {
            await API.Member.Quiz.rateQuiz({ scope: "positive", rating: -1 });
          }
          await API.Member.Quiz.rateQuiz({ scope: "negative", rating: 1 });
        }

        setUserRateHistory(userRate);
      }

      const _ratings = await API.Member.Quiz.getRates();
      setRatings(_ratings.data);
    })();
  }, [userRate]);
  return [
    <h4 className="w-full block text-center text-xl mb-5">
      Did you like this quiz?
    </h4>,
    <div className="flex justify-center items-center">
      <div>
        <a
          onClick={() => setUserRate("negative")}
          disabled={userRate === "negative"}
          className="block py-1 px-3 rounded-lg hover:bg-slate-50 text-lg font-medium text-black mx-2"
        >
          🙄 No
        </a>
        <span className="block w-full text-xs text-neutral-500 text-center mt-2">
          {ratings.negative}
        </span>
      </div>
      <div>
        <a
          onClick={() => setUserRate("positive")}
          disabled={userRate === "positive"}
          className="block py-1 px-3 rounded-lg hover:bg-slate-50 text-lg font-medium text-black mx-2"
        >
          😌 Yes
        </a>
        <span className="block w-full text-xs text-neutral-500 text-center mt-2">
          {ratings.positive}
        </span>
      </div>
    </div>,
  ];
}
