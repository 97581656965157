import classNames from "classnames";

export default function QuestionUnansweredMetrics({ results }) {
  const { questions } = results.data;

  return (
    <div className="card h-full">
      <div className="card-content h-full flex flex-col justify-between">
        <span
          className={classNames(
            "tracking-tighter text-3xl lg:text-5xl letter font-bold",
            questions.answered === questions.total
              ? "text-green-500"
              : "text-orange-400"
          )}
        >
          {questions.total - questions.answered}
        </span>
        <div className="mt-5 lg:mt-16">
          <span className="text-base lg:text-lg font-semibold">Questions</span>
          <span className="block text-sm lg:text-base text-slate-500">
            Left unanswered.
          </span>
        </div>
      </div>
    </div>
  );
}
