import { useEffect, useState } from "react";
import { message } from "antd";
import { Loading } from "@components";
import { Area } from "@ant-design/plots";
import { LineChartOutlined } from "@ant-design/icons";
import * as API from "@api";
import * as R from "ramda";
import moment from "moment";

export default function Chart({ test_id }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [passData, setPassData] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const _res = await API.Member.Scores.getAll({ filter: { test_id } });

      if (!_res || _res.error) {
        return message.error(
          "Unable to load data, please try again or contact us",
          2
        );
      }

      _res.data.rows = R.sort(
        (a, b) => moment(a.created_at).utc().diff(moment(b.created_at).utc()),
        _res.data.rows
      );

      setData(_res.data.rows.map((score) => ({ ...score, category: "Score" })));
      setPassData(
        _res.data.rows.map((score) => ({
          score: score.test.pass_score,
          created_at: score.created_at,
          category: "Pass",
        }))
      );
      setLoading(false);
    })();
  }, [test_id]);

  if (loading) {
    return <Loading compact />;
  }

  if (data.length < 2) {
    return (
      <div className="w-full my-20">
        <LineChartOutlined className="text-3xl text-slate-300 mx-auto block mb-2" />
        <span className="block text-sm text-center text-slate-500 w-full px-5">
          Not enough data to display your progress over time.
        </span>
      </div>
    );
  }

  const config = {
    data: [...data, ...passData],
    xField: "created_at",
    yField: "score",
    smooth: true,
    xAxis: {
      label: null,
    },
    yAxis: {
      max: 100,
    },
    legend: {
      position: "top",
    },
    seriesField: "category",
    isStack: false,
    colorField: "category",
    tooltip: {
      title: (data) => moment(data).format("HH:mm • DD MMM YYYY"),
    },
    color: ({ category }) => (category === "Pass" ? "#17D89E" : "#4706D1"),
    areaStyle: (data) =>
      data.category === "Pass"
        ? {
            fill: "l(270) 0:#ffffff 1:#17D89E",
            stroke: "#17D89E",
          }
        : {
            fill: "l(270) 0:#ffffff 1:#4706D1",
            stroke: "#4706D1",
          },
  };

  return <Area className="my-7" {...config} />;
}
