import { Loading } from "@components";
import * as Icons from "@ant-design/icons";

export default function Intro({ onStart, loading }) {
  return (
    <div
      style={{
        background: "url('/img/quiz-bgr.jpg') center center no-repeat",
        backgroundSize: "cover",
      }}
      className="w-full p-10"
    >
      <img
        src="/img/quiz-title.svg"
        className="block w-full max-h-20 mx-auto mt-5"
      />
      {loading ? (
        <Loading compact />
      ) : (
        <div className="grid grid-cols-3 gap-2 w-full my-20">
          <div className="col-span-1">
            <span className="block text-5xl text-white font-bold text-center w-full">
              15
            </span>
            <span className="block px-4 mt-2 text-xs text-white text-center w-full uppercase opacity-75">
              Random questions
            </span>
          </div>
          <div className="col-span-1">
            <span className="block text-5xl text-white font-bold text-center w-full">
              90
            </span>
            <span className="block px-4 mt-2 text-xs text-white text-center w-full uppercase opacity-75">
              Sec per question
            </span>
          </div>
          <div className="col-span-1">
            <span className="block text-5xl text-white font-bold text-center w-full">
              NO
            </span>
            <span className="block px-4 mt-2 text-xs text-white text-center w-full uppercase opacity-75">
              Skips
            </span>
          </div>
        </div>
      )}
      <div className="text-center flex flex-row flex-wrap justify-center items-center">
        <a
          disabled={loading}
          onClick={() => onStart("numerical-tests")}
          className="rounded-full select-none bg-white text-black py-2 px-5 m-2"
        >
          Numerical <Icons.RightOutlined />
        </a>
        <a
          disabled={loading}
          onClick={() => onStart("verbal-tests")}
          className="rounded-full select-none bg-white text-black py-2 px-5 m-2"
        >
          Verbal <Icons.RightOutlined />
        </a>
        <a
          disabled={loading}
          onClick={() => onStart("logical-tests")}
          className="rounded-full select-none bg-white text-black py-2 px-5 m-2"
        >
          Logical <Icons.RightOutlined />
        </a>
        <a
          disabled={loading}
          onClick={() => onStart("mechanical-tests")}
          className="rounded-full select-none bg-white text-black py-2 px-5 m-2"
        >
          Mechanical <Icons.RightOutlined />
        </a>
      </div>
      <p className="text-xs text-white opacity-75 w-full m-0 mt-10 text-center">
        Good to know: Quiz is designed to shape your time management and problem
        solving skills (which, ideally, you should be able to demonstrate during
        real assessment.) You should take at least 4-5{" "}
        <a className="text-white underline" href="/tests">
          regular tests
        </a>{" "}
        prior to taking the Quiz.
      </p>
    </div>
  );
}
