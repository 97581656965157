import { Button } from "antd";

export default function HelpCard() {
  return (
    <div className="card">
      <div className="card-content">
        <h3 className="mt-0 mb-5 text-xl">Help</h3>
        <div className="flex flex-col items-center justify-center w-full h-52">
          <span className="text-sm text-slate-500 text-center">
            Tutorials will be added soon. Please{" "}
            <a href="mailto:support@aptitude-tests.co.uk">contact us</a> if you
            have questions regarding your membership.
          </span>
        </div>
      </div>
    </div>
  );
}
