import React, { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import classnames from "classnames";
import moment from "moment";

export default function TestCountdown({ test, setTestSession, testSession }) {
  const [timeSpent, setTimeSpent] = useState(0);

  const resetOnNextQuestion = test.time === 0;
  const timelimit =
    test.time === 0 ? test.question_time * 60 * 1000 : test.time * 60 * 1000;
  const [endAt, setEndAt] = useState(
    moment(testSession.testStartedAt).utc() + timelimit
  );

  // On question change, reset timer if time/question
  useEffect(() => {
    if (resetOnNextQuestion) {
      setEndAt(Date.now() + timelimit);
    }
  }, [testSession.questionIdx]);

  const onComplete = () => {
    // Check if time/question
    if (resetOnNextQuestion) {
      // Move to results if last question
      if (testSession.questionIdx + 1 === test.questions.length) {
        return setTestSession({
          ...testSession,
          page: "results",
          questionIdx: 0,
        });
      }

      return setTestSession(
        {
          ...testSession,
          questionIdx: testSession.questionIdx + 1,
        },
        setEndAt(Date.now() + timelimit)
      );
    }

    // Move to results
    return setTestSession({
      ...testSession,
      page: "results",
      questionIdx: 0,
    });
  };

  // Log time spent
  const onTimerTick = () => {
    setTimeSpent(timeSpent + 1);

    // setTestSession({
    //   ...testSession,
    //   timeSpent: testSession.timeSpent + 1,
    // });
  };;

  return (
    <Countdown
      key={
        resetOnNextQuestion ? `${test.id}-${testSession.questionIdx}` : test.id
      }
      onComplete={onComplete}
      onTick={onTimerTick}
      date={endAt}
      renderer={({ minutes, seconds }) => {
        const isDanger = minutes <= 1;
        return (
          <span
            className={classnames("font-medium text-sm", {
              "text-orange-500": isDanger,
            })}
          >
            {zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}
          </span>
        );
      }}
    />
  );
}
