import { useState } from "react";
import { Modal, Select, Input, message } from "antd";
import * as API from "@api/member";
// import { Misc } from "@api";
import * as R from "ramda";

export default function ErrorReporter({
  test,
  testSession,
  onClose,
  visible = false,
}) {
  const [report, setReport] = useState({});
  const [loading, setLoading] = useState(false);

  const onFormChange = (type, value) => {
    return setReport({
      ...report,
      [type]: value,
    });
  };

  const onFormSubmit = async () => {
    setLoading(true);
    if (R.isEmpty(report)) {
      setLoading(false);
      return message.error(
        "Please fill all form fields to report an error.",
        2
      );
    }

    const _res = await API.Errors.report({
      error_type: R.pathOr("N/a", ["error_type"], report),
      error_scope: R.pathOr("N/a", ["error_scope"], report),
      affected_questions: R.pathOr("N/a", ["affected_question"], report),
      test_id: test.id,
      question_id: parseInt(testSession.questionIdx) + 1 || 0,
      comments: R.pathOr("N/a", ["comments"], report),
    });

    setLoading(false);

    if (_res.error) {
      return message.error(
        "Unable to send report, please try again or email us: support@aptitude-tests.co.uk",
        3
      );
    }

    setReport({});
    onClose();

    return message.success(
      "Thank you for sending error report. We will review it and give you a feedback soon.",
      5
    );
  };

  return (
    <Modal
      okButtonProps={{ loading }}
      onOk={onFormSubmit}
      title="Report test error"
      okText="Send Report"
      onCancel={onClose}
      visible={visible}
    >
      <span className="block text-slate-600 mb-1">
        What type of error is it?
      </span>
      <Select
        onChange={(val) => onFormChange("error_type", val)}
        value={R.pathOr(null, ["error_type"], report)}
        className="block w-full mb-3"
        placeholder="Select error type"
      >
        <Select.Option value="Technical (not loading, not showing)">
          Technical (not loading, not showing)
        </Select.Option>
        <Select.Option value="Typo, text">Typo, text</Select.Option>
        <Select.Option value="Other">Other</Select.Option>
      </Select>
      <span className="block text-slate-600 mb-1">Where do you see error?</span>
      <Select
        onChange={(val) => onFormChange("error_scope", val)}
        value={R.pathOr(null, ["error_scope"], report)}
        className="block w-full mb-3"
        placeholder="Select error scope"
      >
        <Select.Option value="Question image, text">
          Question image, text
        </Select.Option>
        <Select.Option value="Question problem">Question problem</Select.Option>
        <Select.Option value="Answers">Answers</Select.Option>
        <Select.Option value="Explanations">Explanations</Select.Option>
        <Select.Option value="Other">Other</Select.Option>
      </Select>
      <span className="block text-slate-600 mb-1">
        Is it only this question?
      </span>
      <Select
        onChange={(val) => onFormChange("affected_question", val)}
        value={R.pathOr(null, ["affected_question"], report)}
        className="block w-full mb-3"
        placeholder="Select affected question"
      >
        <Select.Option value="Yes, this question">
          Yes, this question
        </Select.Option>
        <Select.Option value="No, some other questions">
          No, some other questions as well (tell us below)
        </Select.Option>
      </Select>
      <span className="block text-slate-600 mb-1">
        Please tell us a little more:
      </span>
      <Input.TextArea
        onChange={(e) => onFormChange("comments", e.target.value)}
        rows={3}
        placeholder="Describe steps to reproduce the error. You can leave your suggestion as well."
        value={R.pathOr("", ["comments"], report)}
      />
      <p className="text-sm mt-5 mb-0">
        We review each report and will use the information you provide above to
        resolve the issue as soon as possible. Please accept our apologies for
        inconvenience.
      </p>
    </Modal>
  );
}
