import { useEffect, useState } from "react";
import ResultsInfo from "@components/results/info";
import { Link } from "react-router-dom";
import { Loading } from "@components";
import { Button } from "antd";
import classnames from "classnames";
import * as API from "@api";
import * as R from "ramda";

export default function HistoryCard() {
  const [resultId, setResultId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);

  useEffect(() => {
    (async () => {
      const _res = await API.Member.Scores.getAll({
        limit: 5,
      });

      setResults(R.pathOr([], ["data", "rows"], _res));
      setLoading(false);
    })();
  }, []);

  const onResultsRequested = (id) => {
    setResultId(id);
  };

  const onResultsClose = () => {
    setResultId(null);
  };

  const getResultList = () => {
    if (results.length === 0) {
      if (loading) {
        return <Loading compact />;
      }
      return (
        <p className="flex h-60 items-center justify-center">
          No results to show. Take tests first.
        </p>
      );
    }

    return results.map((res, i) => {
      return (
        <li>
          <div className="flex flex-row items-center">
            <i
              className={classnames("font-bold text-2xl not-italic my-0 mr-5", {
                "text-red-500": !res.passed,
                "text-green-500": res.passed,
              })}
            >
              {res.score}
            </i>
            <div className="flex flex-col">
              <span className="text-base text-black">{res.test.name}</span>
              <i className="not-italic font-normal text-xs text-slate-500">
                {res.passed ? "PASSED" : "FAILED"} &middot;{" "}
                {parseInt(res.time / 60)} min spent &middot;{" "}
                {res.questions_correct}/{res.questions_total} correct
              </i>
            </div>
          </div>
          <Button onClick={() => onResultsRequested(res.id)}>View</Button>
        </li>
      );
    });
  };

  return (
    <div className="card h-full">
      <div className="card-content h-full">
        <div className="mt-0 mb-5 flex items-center justify-between">
          <h3 className="text-xl">Results</h3>
          <Link to="/results">
            <Button type="link" className="p-0">
              All Results
            </Button>
          </Link>
        </div>
        <ul className="test-list mt-5 mb-0">{getResultList()}</ul>
      </div>
      <ResultsInfo
        visible={!!resultId}
        resultId={resultId}
        onClose={onResultsClose}
      />
    </div>
  );
}
