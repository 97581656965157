import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layouts, Loading, Breadcrumb, TestModules } from "@components";
import { message } from "antd";
import classnames from "classnames";
import * as API from "@api";
import * as R from "ramda";

export default function ViewResult() {
  const params = useParams();
  const resultId = params.resultId;

  const [test, setTest] = useState(null);
  const [testSession, setTestSession] = useState({
    page: "review",
    questionIdx: 0,
    timerOn: false,
    explanationsOn: true,
    questionTravelOn: true,
    negativeMarkingOn: false,
    timeSpent: 0,
    userAnswers: [],
    results: {
      data: null,
      submitted: true,
    },
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const _res = await API.Member.Scores.getOne({ id: resultId });

      if (_res?.error || !_res?.data) {
        return message.error(
          _res?.message || "Results not found, try again or contact support",
          2,
          () => (document.location.href = "/results")
        );
      }

      const _resTest = await API.Member.Tests.getOne({
        id: _res.data.score.test.id,
      });

      if (_resTest?.error || !_resTest?.data) {
        return message.error(
          _resTest?.message ||
            "Results not found, try again or contact support",
          2,
          () => (document.location.href = "/results")
        );
      }

      const _resHistory = await API.Member.Scores.getAll({
        filter: { test_id: _resTest.data.test.id },
      });

      if (_resTest?.error || !_resTest?.data) {
        return message.error(
          _resTest?.message ||
            "Results not found, try again or contact support",
          2,
          () => (document.location.href = "/results")
        );
      }

      _resTest.data.test.questions = _resTest.data.test.questions.map((q) => {
        const userRecord = R.find(
          R.propEq("id", q.id),
          _res.data.score.records
        );

        q.answers = q.answers.map((a) => ({
          ...a,
          selected:
            R.find(R.propEq("id", a.id), userRecord.answers)?.selected || null,
        }));

        return q;
      });

      setTest(_resTest.data.test);
      setTestSession({
        ...testSession,
        results: {
          data: {
            ..._res.data.score,
            scores: {
              s: _res.data.score.score,
              z: _res.data.score.percentile,
              p: _res.data.score.percentile,
              a: _res.data.score.accuracy,
            },
            history: _resHistory?.data?.rows || [],
            questions: {
              total: _res.data.score.questions_total,
              answered: _res.data.score.questions_answered,
              correct: _res.data.score.questions_correct,
              incorrect: _res.data.score.questions_incorrect,
            },
          },
          submitted: true,
        },
      });
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return (
      <Layouts.MemberLayout>
        <Loading compact />
      </Layouts.MemberLayout>
    );
  }

  return (
    <Layouts.MemberLayout>
      <div className="container max-content test-page">
        <Breadcrumb
          data={[
            { title: "Results", href: "/results" },
            {
              title: R.pathOr("", ["name"], test),
              href: `/results/${resultId}`,
            },
          ]}
        />
        {testSession.page === "review" && (
          <TestModules.Tabs
            test={test}
            testSession={testSession}
            setTestSession={setTestSession}
          />
        )}
        {testSession.page === "review" && (
          <div className="grid grid-cols-12 gap-4 mt-7">
            <div
              className={classnames("col-span-12", {
                "lg:col-span-8":
                  test.product?.slug !== "situational-judgement-tests",
              })}
            >
              <TestModules.Content
                test={test}
                testSession={testSession}
                setTestSession={setTestSession}
              />
            </div>
            <div
              className={classnames("col-span-12", {
                "lg:col-span-4":
                  test.product?.slug !== "situational-judgement-tests",
              })}
            >
              <TestModules.Navigator
                test={test}
                testSession={testSession}
                setTestSession={setTestSession}
                setTest={setTest}
              />
            </div>
          </div>
        )}
        {testSession.page === "results" && (
          <TestModules.Results
            test={test}
            testSession={testSession}
            setTestSession={setTestSession}
          />
        )}
      </div>
      <TestModules.Offline />
    </Layouts.MemberLayout>
  );
}
