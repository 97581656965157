import { Badge } from "antd";
import GaugeChart from "react-gauge-chart";
import * as R from "ramda";
import ordinal from "ordinal";

export default function PercentileMetrics({ results }) {
  const percentile = R.pathOr(0, ["data", "scores", "z"], results);
  return (
    <div className="card h-full">
      <div className="card-content h-full flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center flex-wrap">
            <span className="font-semibold text-lg text-black m-0 p-0">
              Percentile
            </span>
            {percentile < 70 && <Badge color="#FA1366" text="POOR" />}
            {percentile >= 70 && percentile < 90 && (
              <Badge color="#FFC700" text="AVERAGE" />
            )}
            {percentile >= 90 && <Badge color="#17D89E" text="GOOD" />}
          </div>

          <GaugeChart
            animDelay={200}
            className="gauge-chart"
            style={{ width: "100%", fontWeight: 500 }}
            colors={["#F30B0B", "#1DE148"]}
            nrOfLevels={20}
            percent={percentile / 100}
            fontSize="1.6rem"
            textColor="black"
            marginInPercent={0.02}
            needleColor="#e2e8f0"
            needleBaseColor="#e2e8f0"
          />
        </div>

        <p className="text-base text-slate-500 m-0">
          Percentile indicates your performance on this test compared to the
          other test takers. {ordinal(percentile)} percentile in your case means
          that {percentile}% of other test-takers scored less than you in
          similar conditions.
        </p>
      </div>
    </div>
  );
}
