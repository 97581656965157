import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import config from "@config";

export default function PurchaseWidget({ test }) {
  return (
    <div className="bundle-widget">
      <div
        className="card"
        style={{
          background: "url('/img/bundle-bgr@2x.jpg') left top no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="p-8 lg:p-12">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 lg:col-span-6">
              <i className="lit not-italic">
                <img src="/img/flame.svg" /> 40% OFF TODAY
              </i>
              <span className="title text-xl md:text-2xl lg:text-3xl mt-7 mb-4">
                {test?.company
                  ? `${test.company.name} All-in-One Bundle`
                  : "Ultimate All-in-One Bundle"}
              </span>
              <span className="text-sm md:text-base text-white">
                All tests included: Numerical, Verbal, Situational Judgement
                (SJT), Logical, Diagrammatic, Inductive and Mechanical.
              </span>

              <ul className="feature-list p-0 mt-10 md:mt-16 text-sm md:text-base">
                <li>
                  <img src="/img/icon-check-green.svg" /> 62 Tests, 800+
                  Questions
                </li>
                <li>
                  <img src="/img/icon-check-green.svg" /> Detailed solutions
                </li>
                <li>
                  <img src="/img/icon-check-green.svg" /> 12 months unlimited
                  access
                </li>
                {test?.company && (
                  <li>
                    <img src="/img/icon-check-green.svg" /> Fully updated for{" "}
                    {test.company.name} {new Date().getFullYear()} hiring
                  </li>
                )}
                <li>
                  <img src="/img/icon-check-green.svg" /> Suitable for all
                  positions and levels
                </li>
              </ul>
              <Button
                href={
                  test?.company
                    ? `/order?company=${test.company.slug}`
                    : "/order"
                }
                className="my-5 shadow-lg shadow-gray-900"
                type="primary"
                size="large"
                shape="round"
              >
                Buy Now <s className="font-light mx-2">£39.95</s> £23.99{" "}
                <RightOutlined />
              </Button>
              <a
                target="_blank"
                href={`${config.frontend}/terms-and-conditions`}
                className="block text-white text-xs hover:text-white m-0"
              >
                Secure Payments with Stripe &middot; 14 days money back
                guarantee (T&amp;C's Apply)
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
