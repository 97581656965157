import axios from "axios";
import config from "@config";

const API = `${config.api}/member/users`;

// Returns current user
export const getUser = () =>
  axios
    .get(`${API}/`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Updates current user details
export const update = (data) =>
  axios
    .post(`${API}/details`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Delets current user from DB
export const destroy = () =>
  axios
    .delete(`${API}/`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Updates current user password
export const updatePassword = (data) =>
  axios
    .post(`${API}/details/password`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
