import axios from "axios";
import config from "@config";

const API = `${config.api}/member/errors`;

// Report error
export const report = (data) =>
  axios
    .post(`${API}/`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
