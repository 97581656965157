import { Tag, Avatar, Button, message, Comment, Tooltip } from "antd";
import * as Icons from "@ant-design/icons";
import * as API from "@api";
import { useEffect, useState } from "react";
import { Quiz, Loading } from "@components";
import moment from "moment";

export default function WelcomeCard(params) {
  const [loading, setLoading] = useState(true);
  const [quiz, setQuiz] = useState(false);
  const [news, setNews] = useState([]);

  useEffect(() => {
    (async () => {
      const _res = await API.Member.News.getAll();

      if (!_res || _res.error) {
        return message.error(
          "Error loading news, try again later or contact",
          3
        );
      }

      setNews(_res.data.rows[0]);
      setLoading(false);
    })();
  }, []);

  const onQuizModeChange = () => {
    setQuiz(!quiz);
  };

  return (
    <div className="card grid grid-cols-12 gap-6 lg:gap-0 overflow-hidden">
      <div className="col-span-12 lg:col-span-6">
        <div className="card-content">
          <h3 className="mt-0 mb-5 text-xl">Recent News</h3>
          {loading ? (
            <Loading compact />
          ) : (
            <Comment
              className="newsfeed"
              author={
                <a
                  href="mailto:support@aptitude-tests.co.uk"
                  target="_blank"
                  alt="Contact Support"
                  title="Contact Support"
                >
                  {news.author}
                </a>
              }
              avatar={
                <Avatar
                  className=""
                  size="large"
                  // src={news.avatar}
                  icon="🙎🏻‍♀️"
                  alt={news.author}
                />
              }
              content={
                <div
                  className="newsfeed-comment"
                  dangerouslySetInnerHTML={{ __html: news.content }}
                />
              }
              datetime={
                <Tooltip
                  title={moment(news.created_at)
                    .utc()
                    .format("HH:mm:ss DD/MM/YYYY")}
                >
                  <span>{moment(news.created_at).utc().fromNow()}</span>
                </Tooltip>
              }
            />
          )}
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 bg-black px-5 flex flex-col justify-between">
        <div>
          <h4 className="text-2xl font-semibold text-white text-center mx-auto mt-10 mb-0 flex justify-center items-center w-full">
            Test Quiz{" "}
            <Tag className="m-0 ml-2" color="#ef4444">
              New
            </Tag>
          </h4>
          <span className="block text-base my-1 text-center mx-auto text-neutral-400">
            Psychometric assessment. Reimagined.
          </span>
          <Button
            onClick={onQuizModeChange}
            className="block mx-auto my-5"
            shape="round"
            type="primary"
          >
            Try Quiz <Icons.RightOutlined />
          </Button>
        </div>
        <img
          style={{ maxWidth: 350 }}
          className="w-full block mx-auto"
          src="/img/quiz-preview.png"
        />
        <Quiz visible={quiz} onClose={onQuizModeChange} />
      </div>
    </div>
  );
}
