// import {filter, find, propEq, propSatisfies, isNil, pathOr, map, test} from 'ramda';
import moment from "moment";
import * as R from "ramda";

export default function calc({ test, testSession }) {
  const time =
    testSession.timeSpent ||
    moment().diff(moment(testSession.testStartedAt), "seconds");
  const percentagePerQuestion = 100 / test.questions.length;

  const data = {
    score: 0,
    negative_marking: testSession.negative_markingOn,
    time,
    questions: {
      total: test.questions.length,
      answered: 0,
      correct: 0,
      incorrect: 0,
    },
    records: [],
  };

  test.questions.map((question) => {
    const { answers } = question;
    let isCorrect = false;
    let hasAnswered = !!R.find((a) => a?.selected, answers);

    // Radio answers
    if (question.type === 1 || question.type === 3) {
      // Find correct answer
      let correctAnswer = R.find(R.propEq("correct", 1), answers);

      // Check if selected correct answer
      if (correctAnswer?.selected === 1) {
        isCorrect = true;
      }
    }

    // Checkbox answers
    if (question.type === 3) {
      // Find correct answer
      let correctAnswers = R.filter(R.propEq("correct", 1), answers);
      let correctSelected = R.filter(R.propEq("selected", 1), correctAnswers);

      // Check if selected correct answer
      if (correctAnswers.length === correctSelected.length) {
        isCorrect = true;
      }
    }

    // Sort answers
    if (question.type === 4 || question.type === 5) {
      let numOfAnswers = R.filter(
        R.propSatisfies((x) => x !== 0, "correct"),
        answers
      ).length;
      let numOfCorrectAnswers = 0;

      answers.map((answer) => {
        if (answer.correct === 0 && R.isNil(answer.selected)) {
          return;
        }

        if (answer.correct !== 0 && answer.correct === answer.selected) {
          numOfCorrectAnswers += 1;
        }

        return;
      });

      if (numOfAnswers === numOfCorrectAnswers) {
        isCorrect = true;
      }
    }

    // Add score
    if (isCorrect) {
      data.score += percentagePerQuestion;
      data.questions.correct += 1;
    } else {
      data.questions.incorrect += 1;

      if (data.negative_marking) {
        data.score -= percentagePerQuestion;
      }

      if (data.score < 0) {
        data.score = 0;
      }
    }

    if (hasAnswered) {
      data.questions.answered += 1;
    }

    data.records.push({
      id: question.id,
      type: question.type,
      correct: isCorrect,
      answers: question.answers.map((a) => ({
        id: a.id,
        selected: a?.selected || null,
      })),
    });
  });

  return data;
}
