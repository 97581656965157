import axios from "axios";
import config from "@config";

const API = `${config.api}/member/orders`;

// Get all user orders
export const getAll = (params) =>
  axios
    .get(`${API}/`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Get all user active orders
export const getAllActive = (params) =>
  axios
    .get(`${API}/active`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Create user order
export const create = (data) =>
  axios
    .post(`${API}/`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
